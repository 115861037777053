import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@app/services/api.service';
import { NotificationService } from '@app/services/notification.service';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent {
  private token: string;  chatLoad:boolean=true;  ErrorMessage:boolean =false;  successMessage: boolean=false;  message: any;

  constructor(private apiService: ApiService, private router: Router,private route: ActivatedRoute,private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(pluck('params')).subscribe((params) => {
      this.token = params['token'];
      if (this.token) {this.verifyEmail();}
      else {
        this.notificationService.showErrorNotification('Invalid link, please try again.', 1000);
        this.router.navigate(['/']);
      }
    });
  }
  verifyEmail() {
    let body = {
      token: atob(this.token)
    }
    this.apiService.verifyEmail(body).subscribe((res: any) => {
      if (res) {
        this.ErrorMessage = false;
        this.successMessage=true;
        this.chatLoad=false;
        this.message=res.message
        this.notificationService.showSuccessNotification(res.message, 1000);
      }
    }, (error) => {
      this.notificationService.showErrorNotification(error, 1000);
      this.chatLoad=false;
      this.ErrorMessage = true;
      this.successMessage=false;
      this.message=error
    });
  }
}
