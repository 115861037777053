export interface ListingInfo {
  type: string;
  listingName: string;
  category: string;
  subCategory: string;
  priceType: string;
  price: string;
  id: string;
  _id: string;
  itemCode: string;
  location: string;
  description: string;
  published: boolean;
  images: string[];
  qrCode: string;
  block:string;
  address:string;
  visible:string;
  lat:string;
  lng:string;
  postal_code:string;
  isSelected: boolean;
  percentage:string;
  created: string;
  currency:string;
  adminPercentage:string;
  isApproval:string;
  isDelete: string;
  listingUniqueID:string;
  from:string;
  to:string;
  userId:string;
  user: string;
}

export interface ListingInfoForm {
  listingName: string;
  category: string;
  subCategory: string;
  priceType: string;
  price: string;
  itemCode: string;
  address: string;
  description: string;
  published: boolean;
  images: string[];
  lat:string;
  lng:string;
  postal_code:string;
  percentage:string;
  currency:string;
  isApproval:string;
  isDelete: string;
  adminPercentage:string;
  from:string;
  to:string;
}

export interface ListingInfoApi {
  isApproval: string;
  isDelete: string;
  adminPercentage: string;
  category: string;
  created: string;
  description: string;
  id: string;
  _id: string;
  itemCode: string;
  keywords: string;
  images: string[];
  listingName: string;
  price: string;
  priceType: string;
  published: boolean;
  qrCode: string;
  subCategory: string;
  user: string;
  location: string;
  block: string;
  address:string;
  visible:string;
  lat:string;
  lng:string;
  postal_code:string;
  isSelected: boolean;
  type:string;
  percentage:string;
  currency:string;
  listingUniqueID: string;
  from:string;
  to:string;
  userId: string;
}

export function fromListingInfoJSON(obj?: ListingInfoApi): ListingInfo {
  const translatedObj: ListingInfo = {
    listingName: obj.listingName || '',
    category: obj.category || '',
    subCategory: obj.subCategory || '',
    priceType: obj.priceType || '',
    price: obj.price || '',
    itemCode: obj.itemCode || '',
    location: obj.location || '',
    description: obj.description || '',
    published: obj.published || false,
    images: obj.images || [],
    id: obj.id || '',
    _id: obj._id || '',
    qrCode: obj?.qrCode || '',
    block: obj?.block || '',
    address: obj?.address || '',
    visible: obj?.visible || '',
    lat: obj?.lat || '',
    lng: obj?.lng || '',
    postal_code: obj?.postal_code || '',
    isSelected: obj?.isSelected || false,
    type: obj?.type || '',
    percentage: obj?.percentage || '',
    currency: obj?.currency || '',
    created: obj?.created || '',
    adminPercentage: obj?.adminPercentage || '',
    isApproval: obj?.isApproval || '',
    isDelete: obj?.isDelete || '',
    listingUniqueID: obj?.listingUniqueID || '',
    from: obj?.from || '',
    to: obj?.to || '',
    userId: obj?.userId || '',
    user: obj?.user || ''
  };
  return translatedObj;
}

export function toListingFormGroup(obj?: ListingInfo): ListingInfoForm {
  const translatedObj: ListingInfoForm = {
    listingName: obj.listingName || '',
    category: obj.category || '',
    subCategory: obj.subCategory || '',
    priceType: obj.priceType || '',
    price: obj.price || '',
    itemCode: obj.itemCode || '',
    address: obj.address || '',
    description: obj.description || '',
    published: obj.published || false,
    images: obj.images || [],
    lat:obj?.lat || '',
    lng:obj?.lng || '',
    postal_code:obj?.postal_code || '',
    percentage:obj?.percentage || '',
    currency:obj?.currency || '',
    isApproval:obj?.isApproval || '',
    isDelete: obj?.isDelete || '',
    adminPercentage:obj?.adminPercentage || '',
    from:obj?.from || '',
    to:obj?.to || ''
  };
  return translatedObj;
}