import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ForgotPassowrdRoutingModule } from './forgot-passowrd-routing.module';
import { ForgotPassowrdComponent } from './forgot-passowrd.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material/material.module';
import { ClickKeydownModule } from '@app/utils/click-keydown/click-keydown.module';


@NgModule({
  declarations: [ForgotPassowrdComponent],
  imports: [
    CommonModule,
    ForgotPassowrdRoutingModule,
    MaterialModule,ClickKeydownModule, FlexLayoutModule, ReactiveFormsModule
  ],
  exports: [ForgotPassowrdComponent]
})
export class ForgotPassowrdModule { }
