  <!-----------------------------------User Dashboard----------------------------------------->
  <ng-container *ngIf="currentUser?.role == 'User'">
    <app-activity-dashboard></app-activity-dashboard>
  </ng-container>
  <!-----------------------------------User Dashboard----------------------------------------->


    <!-----------------------------------Admin Dashboard----------------------------------------->
<ng-container *ngIf="currentUser?.role == 'Admin' || currentUser?.role == 'Manager'">
    <app-dashboard></app-dashboard>
  </ng-container>
  <!-----------------------------------Admin Dashboard----------------------------------------->