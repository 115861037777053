import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from '@app/unauthenticated/signup/terms-and-conditions/terms-and-conditions.component';

@Component({
  selector: 'app-create-options',
  templateUrl: './create-options.component.html',
  styleUrls: ['./create-options.component.scss'],
})
export class CreateOptionsComponent {
  currentRole: any;
  constructor(public dialogRef: MatDialogRef<TermsAndConditionsComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {
    let d = JSON.parse(localStorage.getItem('user'))
    this.currentRole = d.role;
  }
}
