import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadWalletRoutingModule } from './load-wallet-routing.module';
import { LoadWalletComponent } from './load-wallet.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClickKeydownModule } from '@app/utils/click-keydown/click-keydown.module';
import { MaterialModule } from '@app/material/material.module';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [LoadWalletComponent],
  imports: [
    CommonModule,
    LoadWalletRoutingModule,
    MaterialModule,
    ClickKeydownModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot(environment.stripe_pk),
  ],
  exports: [LoadWalletComponent]
})
export class LoadWalletModule { }
