import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ActivityDashboardComponent } from './activity-dashboard/activity-dashboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material/material.module';
import { ClickKeydownModule } from '@app/utils/click-keydown/click-keydown.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';


@NgModule({
  declarations: [
    HomeComponent,
    DashboardComponent,
    ActivityDashboardComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    MaterialModule, 
    SlickCarouselModule, 
    ClickKeydownModule, 
    FlexLayoutModule, 
    ReactiveFormsModule
  ]
})
export class HomeModule { }
