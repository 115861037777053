<div class="right-fix" mat-dialog-actions>
  <h1 mat-dialog-title tabindex="-1" cdkFocusInitial align="center">Create New</h1>
  <button mat-button mat-dialog-close><span>&times;</span></button>
</div>
<div mat-dialog-content>
  <p align="center">{{ data }}</p>
</div>
<ng-container *ngIf="currentRole == 'User'">
  <div fxLayoutAlign="center" fxLayout="column" fxLayout.gt-xs="row" class="button-group" fxLayoutGap="20px">
    <a mat-flat-button color="primary" mat-dialog-close routerLink="/qtag/new">Create Qtag</a>
    <a mat-flat-button color="primary" mat-dialog-close routerLink="/eshop/new">Create E-shop</a>
  </div>
</ng-container>
<ng-container *ngIf="currentRole == 'Admin'">
  <div fxLayoutAlign="center" fxLayout="column" fxLayout.gt-xs="row" class="button-group" fxLayoutGap="20px">
    <a mat-flat-button color="primary" mat-dialog-close routerLink="/admin/qtag/new">Create Qtag</a>
    <a mat-flat-button color="primary" mat-dialog-close routerLink="/admin/eshop/new">Create E-shop</a>
  </div>
</ng-container>