import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileViewRoutingModule } from './profile-view-routing.module';
import { ProfileViewComponent } from './profile-view.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material/material.module';
import { ClickKeydownModule } from '@app/utils/click-keydown/click-keydown.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';


@NgModule({
  declarations: [ProfileViewComponent],
  imports: [
    CommonModule,
    ProfileViewRoutingModule,
    MaterialModule, SlickCarouselModule, ClickKeydownModule, FlexLayoutModule, ReactiveFormsModule
  ],
  exports: [ProfileViewComponent]
})
export class ProfileViewModule { }
