import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  currentUser: any;

  constructor() {
    let r = JSON.parse(localStorage.getItem('user'))
    this.currentUser = r
  }
}
