import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmailConfirmationRoutingModule } from './email-confirmation-routing.module';
import { EmailConfirmationComponent } from './email-confirmation.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material/material.module';
import { ClickKeydownModule } from '@app/utils/click-keydown/click-keydown.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';


@NgModule({
  declarations: [EmailConfirmationComponent],
  imports: [
    CommonModule,
    EmailConfirmationRoutingModule,
    MaterialModule, SlickCarouselModule, ClickKeydownModule, FlexLayoutModule, ReactiveFormsModule
  ],
  exports: [EmailConfirmationComponent]
})
export class EmailConfirmationModule { }
