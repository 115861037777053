import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@utils/window/window.provider';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor(@Inject(WINDOW) private window: Window) {}

  public get(key: string): any {
    const value = this.window.sessionStorage.getItem(key);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (ex) {
        return value;
      }
    } else {
      return null;
    }
  }

  public set(key: string, data: any): void {
    if (this.get(key)) {
      this.remove(key);
    }
    this.window.sessionStorage.setItem(key, JSON.stringify(data));
  }

  public remove(key: string): void {
    if (this.get(key)) {
      this.window.sessionStorage.removeItem(key);
    }
  }
}
