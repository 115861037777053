import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionService } from '@services/session.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  loginForm: UntypedFormGroup;
  rememberMe: boolean = false;
  hide = true;
  errorStateMatcher = {
    isErrorState(control: UntypedFormControl | null): boolean {
      return !!(control && control.invalid && control.value);
    },
  };
  previousCompUrl:any = window.history.state.redirect;
  loading: boolean=false;

  constructor(public sessionService: SessionService, private router: Router,private cookieService: CookieService) {
    // redirect to home if already logged in
    if (this.sessionService.authTokenValue ) {
      let d = JSON.parse(localStorage.getItem('user'));
      if(d.firstLogin == "1" || d.firstLogin == "0") {
        this.router.navigate(['/profile/edit'])
       }
       else if(d.firstLogin == "2") {
        this.router.navigate(['/dashboard']);
       }
    }
  }

  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required]),
    });

    // Check if there are saved email and password, and populate the fields
    // const rememberedEmail = atob(sessionStorage.getItem('rememberedEmail'));
    // const rememberedPassword = atob(sessionStorage.getItem('rememberedPassword'));

    const rememberedEmail = this.cookieService.get('rememberedEmail');
    const rememberedPassword = this.cookieService.get('rememberedPassword');

    if (rememberedEmail && rememberedPassword) {
      // Populate the email and password fields
      this.loginForm.patchValue({
        email: rememberedEmail,
        password: rememberedPassword
      });
      // Check the "Remember me" checkbox
      this.rememberMe = true;
    }
  }

  remember(event) {
    this.rememberMe = true;
  }

  loginUser() {
    if (this.loginForm.valid) {
      if (this.rememberMe) {
        // Save email and password if "Remember me" checkbox is checked
        // sessionStorage.setItem('rememberedEmail', btoa(this.loginForm.value.email));
        // sessionStorage.setItem('rememberedPassword', btoa(this.loginForm.value.password));

        this.cookieService.set('rememberedEmail', this.loginForm.value.email);
      this.cookieService.set('rememberedPassword', this.loginForm.value.password);
      } else {
        // Remove saved email and password if "Remember me" checkbox is unchecked
        // sessionStorage.removeItem('rememberedEmail');
        // sessionStorage.removeItem('rememberedPassword');

        this.cookieService.delete('rememberedEmail');
      this.cookieService.delete('rememberedPassword');
      }
      this.loading = true;
      let isRedirct:boolean;
      if( this.previousCompUrl && this.previousCompUrl !== undefined && this.previousCompUrl !== null ){ isRedirct = true }
      this.sessionService.loginUser(this.loginForm.value, isRedirct);
      this.loginForm.reset();
    }
  }

  getEmailErrorMessage() {
    if (this.email.hasError('required')) {return 'You must enter a value';}
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  getPasswordErrorMessage() {return this.password.hasError('required') ? 'You must enter a value' : '';}

  get email() {return this.loginForm.get('email');}

  get password() {return this.loginForm.get('password');}
}
