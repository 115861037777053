import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ClickKeydownModule } from '@utils/click-keydown/click-keydown.module';
import { MaterialModule } from '../material/material.module';
import { PrivacyPolicyComponent } from './signup/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './signup/terms-and-conditions/terms-and-conditions.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { VerifyEmailPopupComponent } from './verify-email-popup/verify-email-popup.component';
import { LoginConfirmationComponent } from './login-confirmation/login-confirmation.component';
import { SigninModule } from '@app/unauthenticated/signin/signin.module';
import { SignupModule } from '@app/unauthenticated/signup/signup.module';
import { ForgotPassowrdModule } from '@app/unauthenticated/forgot-passowrd/forgot-passowrd.module';
import { ResetPasswordModule } from '@app/unauthenticated/reset-password/reset-password.module';
import { ProfileViewModule } from '@app/unauthenticated/profile-view/profile-view.module';
import { EstoreQrCodeModule } from '@app/unauthenticated/estore-qr-code/estore-qr-code.module';
import { ListingQrCodeModule } from '@app/unauthenticated/listing-qr-code/listing-qr-code.module';
import { EmailConfirmationModule } from '@app/unauthenticated/email-confirmation/email-confirmation.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@app/unauthenticated/signin/signin.module').then((m) => m.SigninModule)
  },
  {
    path: 'login',
    loadChildren: () => import('@app/unauthenticated/signin/signin.module').then((m) => m.SigninModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('@app/unauthenticated/signup/signup.module').then((m) => m.SignupModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('@app/unauthenticated/forgot-passowrd/forgot-passowrd.module').then((m) => m.ForgotPassowrdModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('@app/unauthenticated/reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
  },
  {
    path: 'profile/Profile-view/:uniqueID',
    loadChildren: () => import('@app/unauthenticated/profile-view/profile-view.module').then((m) => m.ProfileViewModule)
  },
  {
    path: 'eshopQr/:id',
    loadChildren: () => import('@app/unauthenticated/estore-qr-code/estore-qr-code.module').then((m) => m.EstoreQrCodeModule)
  },
  {
    path: 'qtagQr/:id',
    loadChildren: () => import('@app/unauthenticated/listing-qr-code/listing-qr-code.module').then((m) => m.ListingQrCodeModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('@app/unauthenticated/email-confirmation/email-confirmation.module').then((m) => m.EmailConfirmationModule)
  }
];

@NgModule({
    declarations: [
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        VerifyEmailPopupComponent,
        LoginConfirmationComponent
    ],
    imports: [CommonModule, MaterialModule, SlickCarouselModule, ClickKeydownModule, FlexLayoutModule,FormsModule ,ReactiveFormsModule, RouterModule.forChild(routes),SigninModule,SignupModule,ForgotPassowrdModule,ResetPasswordModule,ProfileViewModule,EstoreQrCodeModule,ListingQrCodeModule,EmailConfirmationModule],
    exports: [RouterModule]
})
export class UnAuthenticatedModule {}
