import { Component, Inject } from '@angular/core';
import { TermsAndConditionsComponent } from '../signup/terms-and-conditions/terms-and-conditions.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@app/services/api.service';
import { NotificationService } from '@app/services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify-email-popup',
  templateUrl: './verify-email-popup.component.html',
  styleUrls: ['./verify-email-popup.component.scss']
})
export class VerifyEmailPopupComponent {
  
  constructor(public dialogRef: MatDialogRef<TermsAndConditionsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,public apiService:ApiService,public notificationService:NotificationService) {}
    
  ngOnInit(): void {}
    
  verifyEmail() {
    let body={email:this.data?.email}
      this.apiService.resendVerifyEmail(body).subscribe((res: any) => {
        if (res) {this.notificationService.showSuccessNotification(res.message,1000);}
        else {this.notificationService.showErrorNotification(res.message,1000);}
      },(error) => {this.notificationService.showErrorNotification(error,1000);});
    }
}
