import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EstoreQrCodeComponent } from './estore-qr-code/estore-qr-code.component';

const routes: Routes = [
  {path: '',component: EstoreQrCodeComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EstoreQrCodeRoutingModule { }
