import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[qClickKeydown]',
})
export class ClickKeydownDirective {
  @Output() qClickKeydown = new EventEmitter();
  @Input() keyTypes = [ENTER, SPACE];

  constructor(private el: ElementRef, private renderer: Renderer2) {
    // add empty href's to anchor tags for accessibility
    if (this.el.nativeElement.tagName === 'A') {
      if (!this.el.nativeElement.href) {
        this.renderer.setAttribute(this.el.nativeElement, 'href', 'javascript:void(0);');
      }
      // add tabindex's to other elements
    } else if (!this.el.nativeElement.tabIndex || this.el.nativeElement.tabIndex !== 0) {
      this.renderer.setAttribute(this.el.nativeElement, 'tabindex', '0');
    }
  }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent) {
    // Click event is fired on enter in firefox so let's make sure it's a REAL click event
    if (event.clientY !== 0 && event.clientX !== 0) {
      this.emit(event);
    }
  }

  @HostListener('keydown', ['$event'])
  keydownEvent(event: KeyboardEvent) {
    const key = event.keyCode || event.which;

    // Preventing default browser scroll behaviour for space
    if (key === SPACE) {
      this.preventDefault(event);
    } else if (this.keyTypes.includes(key)) {
      this.emit(event);
    }
  }

  @HostListener('keyup', ['$event'])
  // Space fires click on keydown in firefox so we have to handle it with a keyup.
  // Bug reported here:  https://bugzilla.mozilla.org/show_bug.cgi?id=1220143
  keyupEvent(event: KeyboardEvent) {
    const key = event.keyCode || event.which;
    if (this.keyTypes.includes(key) && key === SPACE) {
      this.emit(event);
    }
  }

  private emit(event) {
    if (this.isNotAnchor()) {
      this.preventDefault(event);
    }
    this.qClickKeydown.emit(event);
  }

  private preventDefault(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private isNotAnchor(): boolean {
    const role = this.el.nativeElement.attributes.role && this.el.nativeElement.attributes.role.value;
    const href = this.el.nativeElement.attributes.href && this.el.nativeElement.attributes.href.value;
    return this.el.nativeElement.tagName !== 'A' || role === 'button' || href === 'javascript:void(0);';
  }
}
