<div fxLayout="column" fxLayoutAlign="center" class="container">
    <div class="d-flex align-item-center">
        <h1 class="all-view-head"><a (click)="back()" class="bkBtn"><mat-icon>arrow_back_ios</mat-icon> Load Wallet Instructions</a></h1>
    </div>
    <mat-card>
        <div class="devide-part">
            <div class="back-page">
                <div class="mdl-cell mdl-cell--12-col">
                    <div class="right-crd">
                        <div class="mdl-grid">
                            <mat-tab-group dynamicHeight #tabGroup [class]="card_show ? 'w-50' : 'w-30'" [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)">
                                <mat-tab label="Method"> 
                                    <p class="head">Load and pay with Local Dollars to earn the  fastest.</p>
                                    <div class="btn_center">
                                        <button mat-raised-button class="method_btn" (qClickKeydown)="changeTab('e-Transfer')">e-Transfer</button>
                                    </div>
                                    <div class="btn_center">
                                        <button mat-raised-button class="method_btn" (qClickKeydown)="changeTab('Bill Payee')">Bill Payee</button>
                                    </div>
                                    <div class="btn_center">
                                        <button mat-raised-button class="method_btn" (qClickKeydown)="changeTab('Card')">Card</button>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Pay"> 
                                    <ng-container #eTransfer *ngIf="eTransfer_show">
                                        <div style="padding-top: 30px;">
                                            <p class="head_text"> Send the Interac e-Transfer to email:</p>
                                            <div class="d-flex align-items-center">
                                                <input type="text" name="" placeholder="{{secret?.localFrequency}}" class="input_box" readonly>
                                                <a href="javascript:void(0)" class="copy_btn" (qClickKeydown)="copyText(secret?.localFrequency)">copy</a>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="head_text">Copy your Unique ID code into the e-transfer <b>question</b>:</p>
                                            <div class="d-flex align-items-center">
                                            <input type="text" name="" placeholder="{{currentUser?.uniqueID}}" class="input_box" readonly>
                                            <a href="javascript:void(0)" class="copy_btn" (qClickKeydown)="copyText(currentUser?.uniqueID)">copy</a>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="head_text">Copy this word into the e-transfer <b>Secret Password</b>:</p>
                                            <div class="d-flex align-items-center">
                                            <input type="text" name="" placeholder="{{secret?.secret}}" class="input_box" readonly>
                                            <a href="javascript:void(0)" class="copy_btn" (qClickKeydown)="copyText(secret?.secret)">copy</a>
                                            </div>
                                        </div>
                                        <p class="head_text">e-Transfers are usually fast, but at busy times they can take 2-3 business day to process. Pls check your wallet balance after a few days.</p>
                                        <button mat-raised-button color="dark" class="cancel_btn" type="button" (click)="previousStep()">Cancel</button>
                                    </ng-container>
                                    <ng-container #billing *ngIf="billing_show">
                                        <div style="padding-top: 30px;">
                                            <p class="head_text">Within your Internet Banking Bill Payments, Add the following as a payee and Once your card is successfully added as a payee, select "Pay a Bill":</p>
                                            <div class="d-flex align-items-center">
                                            <input type="text" name="Bill Payee" placeholder="{{secret?.payBill}}" class="input_box" readonly>
                                            <a href="javascript:void(0)" class="copy_btn" (qClickKeydown)="copyText(secret?.payBill)">copy</a>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="head_text">Copy this code into the account number:</p>
                                            <div class="d-flex align-items-center">
                                            <input type="text" name="account number" placeholder="{{currentUser?.uniqueID}}" class="input_box" readonly>
                                            <a href="javascript:void(0)" class="copy_btn" (qClickKeydown)="copyText(currentUser?.uniqueID)">copy</a>
                                            </div>
                                        </div>
                                        <p>Bill Payments are usually processed in 2-4 business day. Pls check your wallet balance after a few days.</p>
                                        <button mat-raised-button color="dark" class="cancel_btn" type="button" (click)="previousStep()">Cancel</button>
                                    </ng-container>
                                    <ng-container #card *ngIf="card_show">
                                        <div class="mt-2">
                                            <label for="amount" class="label-cus">Amount</label>
                                            <input type="number" matInput class="input-custom" placeholder="Enter Amount..." [(ngModel)]="amount" name="amount" id="amount" [min]="limit?.loadLimit">
                                            <span class="text-danger" *ngIf="!amount">{{errorMessage1}}</span><br>
                                            <span class="text-danger" *ngIf="amount < limit.loadLimit">{{errorMessage}}</span>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mt-2"><label class="label-cus" for="card">Card Information</label></div>
                                        <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions" (change)="change($event)"></ngx-stripe-card>
                                        <span class="text-danger" *ngIf="!card.element">{{errorMessage2}}</span>
                                        <div class="row">
                                            <div class="col-md-6" >
                                                <button mat-raised-button color="dark" class="submit_btn" type="button" (click)="submitted()">Pay</button>
                                            
                                            </div> 
                                            <div class="col-md-6">
                                                <button mat-raised-button color="dark" class="cancel_btn" type="button" (click)="previousStep()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                    </ng-container>
                                </mat-tab>
                              </mat-tab-group>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </mat-card>

    <ng-container *ngIf="loading">
        <div class="spin">
            <mat-spinner>Downloading...</mat-spinner>
        </div>
    </ng-container>