<div fxLayout="row" fxLayoutAlign="center" class="container" ngClass.gt-md="container-desktop">
    <img class="background-img" src="/assets/logo_bg_210.svg" alt="logo" loading="lazy">
    <div  fxLayoutAlign="center center">
        <mat-card fxLayout="column" fxLayoutAlign="center">
            <h1>Log In</h1>
            <img src="/assets/logo.svg" loading="lazy" alt="logo" aria-label="logo"/>
            <p class="header-text">Welcome back! Please login to account</p>
            <form [formGroup]="loginForm" (ngSubmit)="loginUser()" fxLayout="column" fxLayoutAlign="center">
                <mat-form-field appearance="fill">
                    <mat-label>Enter Email Address</mat-label>
                    <input matInput placeholder="Email" formControlName="email" type="email" autocomplete="username" required [errorStateMatcher]="errorStateMatcher" />
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="email.invalid">{{ getEmailErrorMessage() }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="current-password" required [errorStateMatcher]="errorStateMatcher" />
                    <button mat-icon-button matSuffix (qClickKeydown)="hide = !hide" [attr.aria-label]="hide ? 'Show password' : 'Hide Password'">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
                    <mat-error *ngIf="password.invalid">{{ getPasswordErrorMessage() }}</mat-error>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="remember-me-container">
                    <mat-checkbox color="primary" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}" (ngModelChange)="remember($event)"><span>Remember me</span></mat-checkbox>
                    <a mat-button color="warn" routerLink="/forgot-password">Forgot Password ?</a>
                </div>
                <button mat-flat-button color="warn" type="submit" [disabled]="!loginForm.valid || sessionService.loading" class="bold-text"><span class=""><i class="fa fa-spinner" *ngIf="sessionService.loading"></i>Login</span></button>
            </form>
            <div fxLayout="row" fxLayoutAlign="center center">
                <p>Don't have account?
                    <a mat-button color="warn" class="bold-text" routerLink="/sign-up">Sign Up</a>
                </p>

            </div>
        </mat-card>
    </div>
    <div fxHide fxShow.gt-md fxFlex="60%" class="image-container"></div>
</div>
<ng-container *ngIf="sessionService.loading">
    <div class="spin">
      <mat-spinner>Downloading...</mat-spinner>
    </div>
</ng-container>