import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EshopService {
  cache: { eshops: any } = { eshops: null };

  constructor(private apiService: ApiService) {}

  getAllEshops(cleanCache?: boolean): Observable<any> {
    if (cleanCache) {
      this.cache.eshops = null;
    }
    if (this.cache.eshops === null) {
      const options: any = {
        params: {
          ts: Date.now(),
        },
      };
      return this.apiService.getAllEshops(options).pipe(
        map((response) => {
          this.cache.eshops = response;
          return this.cache.eshops;
        })
      );
    } else {
      return of(this.cache.eshops);
    }
  }

  createEshop(data: any): Observable<any> {
    return this.apiService.createEshop(data);
  }

  updateEshop(data: any, id): Observable<any> {
    return this.apiService.updateEshop(data, id);
  }

  deleteEshop(id: string): Observable<any> {
    return this.apiService.deleteEshop(id).pipe(concatMap(() => this.getAllEshops(true)));
  }
}
