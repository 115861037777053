import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@app/services/api.service';
import { NotificationService } from '@app/services/notification.service';
import { SessionService } from '@app/services/session.service';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  hide1 = true; hide2 = true;
  resetPasswordForm: UntypedFormGroup;
  private token: string;
  regularExpression  = '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}';
  constructor(private route: ActivatedRoute,private apiService: ApiService,private router: Router,private notificationService: NotificationService,private sessionService:SessionService) {
    if (this.sessionService.authTokenValue ) {
      this.sessionService.authTokenSubject.next(null);
      sessionStorage.clear()
      localStorage.clear()
   }
  }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(pluck('params')).subscribe((params) => {this.token = atob(params['token']);});
    this.resetPasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(this.regularExpression)]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
    });
    this.resetPasswordForm.setValidators(this.matchPassword.bind(this));
  }

  resetPasswordSubmit() {
    if (this.resetPasswordForm.valid) {
      const model = { ...this.resetPasswordForm.value, token: this.token };
      this.apiService.resetPwd(model).subscribe((res) => {
          this.notificationService.showSuccessNotification(res.message,1000);
          this.router.navigate(['sign-in']);
        },(error) => {this.notificationService.showErrorNotification('Invalid url, please try again.',2000);}
      );
    }
  }

  getPasswordErrorMessage() {
    if (this.password.hasError('required')) {return 'You must enter a value';}
    if (this.password.hasError('pattern')) {return 'Password should contain special character,One capital and number'}
    return this.password.hasError('minlength') ? 'Minimum length should be 8 characters' : '';
  }

  getConfirmPasswordErrorMessage() {
    if (this.confirmPassword.hasError('required')) {return 'You must enter a value';}
    return this.confirmPassword.hasError('passwordMatch') ? 'Password should match' : '';
  }

  get password() {return this.resetPasswordForm?.get('password');}

  get confirmPassword() {return this.resetPasswordForm.get('confirmPassword');}

  private matchPassword(fGroup: UntypedFormGroup) {
    const password = fGroup.get('password').value;
    const passwordConfirmation = fGroup.get('confirmPassword');
    if (passwordConfirmation.value && password !== passwordConfirmation.value) {passwordConfirmation.setErrors({ passwordMatch: true });}
  }
}
