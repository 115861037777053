<div class="my-eshop">

    <div class="demo-grid-1 mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
            <div class="content-top-heading">
                <h3>
                    <a *ngIf="user?.id" (click)="back()" style="cursor: pointer;color:black;position: relative;
                    top: 4px;">
                        <mat-icon>arrow_back_ios</mat-icon>
                    </a>Qtag View</h3>
            </div>
        </div>
    </div>

    <ng-container *ngIf="notPublish == false">
        <div class="demo-grid-1 mdl-grid">
            <div class="mdl-cell mdl-cell--8-col wrapper">
                <div class="eshop-left">
                    <div class="image-eshop">
                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                            <div ngxSlickItem *ngFor="let slide of photos" class="slide">
                                <img class="eshop-full-img" src="{{ slide?.photoUrl ?? ''}}" loading="lazy" alt="listPics" aria-label="listPics">
                            </div>
                        </ngx-slick-carousel>
                    </div>
                    <div class="SilverSun">
                        <div class="Silver-1">
                            <p class="SilverSunP">{{publicList?.listingName ?? 'Men Hair Cut'}} {{publicList?.listingUniqueID ? '('+publicList?.listingUniqueID+')': ''}}</p>
                            <p class="SilverSunP1">{{publicList?.price != 'undefined' ? 'CAD ' +publicList?.price : ''}}</p>
                        </div>
                        <ul class="Chat-ul" *ngIf="this.user?.id !== this.publicList?.user">
                            <li><button class="addCart" *ngIf="user?.role == 'User' && addtoCartOption" (click)="addToCart(publicList)">Add to Cart</button></li>
                            <li><button style="padding: 15px 5px 15px 5px;" *ngIf="addedInCart && user?.id !== publicList?.user" routerLink="/cart">Go to Cart</button></li>
                            <li><button *ngIf="isFollowingCurrentList && user" (click)="unfollowButton(publicList)">Unfollow</button></li>
                            <li><button *ngIf="!isFollowingCurrentList || !user" (click)="followButton(publicList)">Follow</button></li>
                            <li *ngIf="user?.role == 'User' && session?.authTokenValue"><button (click)="navigateChat()" style="cursor: pointer;">Chat</button></li>
                        </ul>
                    </div>
                    <div class="SilverSun SilverSun-mar">
                        <div class="Silver-1-content">
                            <p class="Silver-Description">Description</p>
                            <p class="Silver-Description-1">{{publicList?.description ?? ""}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mdl-cell mdl-cell--4-col">
                <div class="eshop-right">
                    <div class="list-details">
                        <div class="about-4">
                            <p class="about-1">Owner</p>
                            <p class="about-2" (qClickKeydown)="navigateProfile()" style="cursor: pointer;"><span>{{userData?.uniqueID ?? ''}}</span></p>
                        </div>
                        <div class="about-4" style="margin-top: 0;">
                            <p class="about-1">Category</p>
                            <p class="about-2"><span>{{publicList?.category ?? 'Service'}}</span></p>
                        </div>
                        <div class="about-4">
                            <p class="about-1">Subcategory</p>
                            <p class="about-2"><span>{{publicList?.subCategory ?? 'Salon'}}</span></p>
                        </div>
                        <div class="about-4">
                            <p class="about-1">Location</p>
                            <p class="about-2"><span>{{publicList?.postal_code != 'undefined' ? publicList?.postal_code : '-'}}</span></p>
                        </div>
                    </div>
                    <div class="maptop">
                        <p class="maptop-p">Details on Map</p>
                        <a class="maptop-a" href="javascript:void(0)" [matMenuTriggerFor]="mapMenu">Show All List</a>
                        <mat-menu #mapMenu="matMenu">
                            <div mat-menu-item>
                            <h4 style="text-align: center;">Map Detail</h4>
                            <div>
                                <p *ngIf="publicList?.address"><b>Address: </b>{{publicList?.address}}</p>
                                <p *ngIf="publicList?.postal_code"><b>Postal Code: </b>{{publicList?.postal_code}}</p>
                            </div>
                            </div>
                        </mat-menu>
                    </div>
                    <div class="maptop-img">
                        <google-map #myGoogleMap height="200px" width="100%" [zoom]="zoom" [center]="center" [options]="options" (mapClick)="eventHandler($event,'mapClick')" (mapDblclick)="eventHandler($event,'mapDblclick')" (mapDrag)="eventHandler($event,'mapDrag')" (mapDragend)="eventHandler($event,'mapDragend')"
                            (mapRightclick)="eventHandler($event,'mapRightclick')" (positionChanged)="eventHandler($event,'marker_positionChanged')" (zoomChanged)="eventHandler($event,'zoomChanged')">
                            <map-marker #markerElem="mapMarker" *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options" (mapClick)="openInfo(markerElem, marker.info)">
                            </map-marker>
                            <map-info-window>{{ infoContent }}</map-info-window>
                        </google-map>
                    </div>
                    <div class="gold-map">
                        <div class="media-gold">
                            <div class="gold-content">
                                <p class="gold-content-p">{{address1 ? address1 : ''}}</p>
                                <p class="gold-content-p-1">{{ address2 ? address2 + ', ' : ''}} {{address3 ? address3 + ', ' : ''}} <br> {{ address4 ? address4 + ' ' : ''}} {{address5 ? address5 : ''}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="barcode-flx">
                        <img class="barcode-flx-img" [src]="veiwQRUrl+publicList?.id ?? 'assets/images/barcode.png'" loading="lazy" alt="qrCode" aria-label="qrCode">
                    </div>
                    <ul class="ul-last">
                        <li><a href="javascript:void(0)" [disabled]="loading == true" (qClickKeydown)="downloadCode(publicList)" [disabled]="loading"><i class="fa-solid fa-download"></i> Download</a></li>
                        <li><a href="javascript:void(0)" (qClickKeydown)="openShareOptions(publicList)"><i class="fa-solid fa-share"></i> Share</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="notPublish == true">
        <div class="demo-grid-1 mdl-grid blur">
            <div class="mdl-cell mdl-cell--8-col">
                <div class="eshop-left">
                    <div class="image-eshop">
                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                            <div ngxSlickItem *ngFor="let slide of photos" class="slide">
                                <img class="eshop-full-img" src="{{ slide.photoUrl ?? ''}}" loading="lazy" alt="listPics" aria-label="listPics">
                            </div>
                        </ngx-slick-carousel>
                    </div>
                    <div class="SilverSun">
                        <div class="Silver-1">
                            <p class="SilverSunP">{{publicList?.listingName ?? 'Men Hair Cut'}}</p>
                            <p class="SilverSunP1">{{publicList?.price != 'undefined' ? 'CAD ' +publicList?.price : ''}}</p>
                        </div>
                        <ul class="Chat-ul">
                            <li><button class="addCart" *ngIf="user?.role == 'User' && addtoCartOption" (click)="addToCart(publicList)">Add to Cart</button></li>
                            <li><button style="padding: 15px 5px 15px 5px;" *ngIf="addedInCart" routerLink="/cart">Go to Cart</button></li>
                            <li><button>Unfollow</button></li>
                            <li><button routerLink="/chat" style="cursor: pointer;">Chat</button></li>
                        </ul>
                    </div>
                    <div class="SilverSun SilverSun-mar">
                        <div class="Silver-1-content">
                            <p class="Silver-Description">Description</p>
                            <p class="Silver-Description-1">{{publicList?.description ?? ""}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mdl-cell mdl-cell--4-col">
                <div class="eshop-right">
                    <div class="about-4" style="margin-top: 0;">
                        <p class="about-1">Category</p>
                        <p class="about-2"><span>{{publicList?.category ?? 'Service'}}</span></p>
                    </div>
                    <div class="about-4">
                        <p class="about-1">Subcategory</p>
                        <p class="about-2"><span>{{publicList?.subCategory ?? 'Salon'}}</span></p>
                    </div>
                    <div class="about-4">
                        <p class="about-1">Location</p>
                        <p class="about-2"><span>{{publicList?.postal_code ?? ''}}</span></p>
                    </div>
                    <div class="maptop">
                        <p class="maptop-p">Details on Map</p>
                        <a class="maptop-a" href="javascript:void(0)">Show All List</a>
                    </div>
                    <div class="maptop-img">
                        <google-map #myGoogleMap height="200px" width="100%" [zoom]="zoom" [center]="center" [options]="options" (mapClick)="eventHandler($event,'mapClick')" (mapDblclick)="eventHandler($event,'mapDblclick')" (mapDrag)="eventHandler($event,'mapDrag')" (mapDragend)="eventHandler($event,'mapDragend')"
                            (mapRightclick)="eventHandler($event,'mapRightclick')" (positionChanged)="eventHandler($event,'marker_positionChanged')" (zoomChanged)="eventHandler($event,'zoomChanged')">
                            <map-marker #markerElem="mapMarker" *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options" (mapClick)="openInfo(markerElem, marker.info)">
                            </map-marker>
                            <map-info-window>{{ infoContent }}</map-info-window>
                        </google-map>
                    </div>
                    <div class="gold-map">
                        <div class="media-gold">
                            <div class="gold-content">
                                <p class="gold-content-p">{{address1 ? address1 : ''}}</p>
                                <p class="gold-content-p-1">{{ address2 ? address2 + ', ' : ''}} {{address3 ? address3 + ', ' : ''}} <br> {{ address4 ? address4 + ' ' : ''}} {{address5 ? address5 : ''}}</p>
                            </div>
                        </div>
                        <a class="span-dot" href="javascript:void(0)" [matMenuTriggerFor]="menu"><span></span><span></span><span></span></a>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (qClickKeydown)="downloadCode(publicList)">
                          <mat-icon>download</mat-icon>
                          <span>Download</span>
                        </button>
                    </mat-menu>
                    </div>
                    <div class="barcode-flx">
                        <img class="barcode-flx-img" [src]="publicList?.qrCode ?? 'assets/images/barcode.png'" loading="lazy" alt="qrCode" aria-label="qrCode">
                    </div>
                    <ul class="ul-last">

                        <li><a href="javascript:void(0)" [disabled]="loading == true" (qClickKeydown)="downloadCode(publicList)"><i class="fa-solid fa-download"></i> Download</a></li>
                        <li><a href="javascript:void(0)" (qClickKeydown)="openShareOptions(publicList)"><i class="fa-solid fa-share"></i> Share</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="notPub">
            <p>Qtag is not published for the public</p>
        </div>
    </ng-container>
</div>
<ng-container *ngIf="loading">
    <div class="spin">
        <mat-spinner>Downloading...</mat-spinner>
    </div>
</ng-container>

<div class="contBlock" #contentToConvert id="contentToConvert" style="display: flex;flex-direction: column;align-items: center;margin-top: -200000px; position: absolute;padding: 10px 0;">
    <img src="/assets/logo.png" style="max-width: 100px;margin-bottom: 10px;padding-bottom: 0;" alt="logo" aria-label="logo"/>
    <img src="{{downloadQr}}" alt="qrCode" aria-label="qrCode" class="qrimg">
    <a class="qrName" href="javascript:void(0)">{{download?.listingName+ '('+ (download?.listingUniqueID != 'undefined' ? download?.listingUniqueID : '')+')'}}</a>
</div>

<div class="contBlock" id="contentToConvert" style="position: absolute; width: 60%; margin-top:-200000px;">
    <img src="/assets/logo.png" alt="logo" aria-label="logo" style="max-width: 120px;margin-top: 10px;margin-bottom: 10px;"/>
    <img src="{{downloadQr}}" alt="qrCode" aria-label="qrCode" class="qrimg">
    <a class="qrName" href="javascript:void(0)">{{download?.listingName+ '('+ download?.listingUniqueID+')'}}</a>
</div>