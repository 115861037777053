<div fxLayout="row" fxLayoutAlign="center" class="container" ngClass.gt-md="container-desktop">
    <div fxFlex.gt-md="40%" fxLayoutAlign="center center">
        <mat-card fxLayout="column" fxLayoutAlign="center">
            <img src="/assets/logo.svg" loading="lazy" alt="logo" aria-label="logo"/>
            <div fxLayout="row" fxLayoutAlign="center" class="tnc-container" *ngIf="successMessage">
                <h1>{{message}}</h1>
            </div>
            <div fxLayout="row" fxLayoutAlign="center" class="tnc-container" *ngIf="ErrorMessage">
                <h1>{{message}}</h1>
            </div>
            <div fxLayout="row" fxLayoutAlign="center" class="tnc-container" *ngIf="chatLoad">
                <span><b>Please wait while we confirming your email</b></span>
                <div class="spin" style="text-align: center;margin-bottom: 20px;">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <button mat-flat-button color="primary" routerLink="/" type="submit">Login</button>
            </div>
        </mat-card>
    </div>
    <div fxHide fxShow.gt-md fxFlex="60%" class="image-container"></div>
</div>