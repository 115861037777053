import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path:'dashboard',
    loadChildren: () => import('@app/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('@app/user-profile/user-profile.module').then((m) => m.UserProfileModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'eshop',
    loadChildren: () => import('@app/eshop/eshop.module').then((m) => m.EshopModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'qtag',
    loadChildren: () => import('@app/listing/listing.module').then((m) => m.ListingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'network',
    loadChildren: () => import('@app/network/network.module').then((m) => m.NetworkModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'map-view',
    loadChildren: () => import('@app/map-view/map-view.module').then((m) => m.MapViewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('@app/notification/notification.module').then((m) => m.NotificationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    loadChildren: () => import('@app/search/search.module').then((m) => m.SearchModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'setting',
    loadChildren: () => import('@app/setting/setting.module').then((m) => m.SettingModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'chat',
  //   loadChildren: () => import('@app/chat/chat.module').then((m) => m.ChatModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'chat',
    loadChildren: () => import('@app/new-chat/new-chat.module').then((m) => m.NewChatModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('@app/admin-pannel/admin-pannel.module').then((m) => m.AdminPannelModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'analytics',
    loadChildren: () => import('@app/analtics/analtics.module').then((m)=> m.AnalticsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('@app/checkout/checkout.module').then((m)=> m.CheckoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wallet',
    loadChildren: () => import('@app/wallet/wallet.module').then((m)=> m.WalletModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wallet/loadWallet',
    loadChildren: () => import('@app/load-wallet/load-wallet.module').then((m)=> m.LoadWalletModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cart',
    loadChildren: () => import('@app/cart/cart.module').then((m) => m.CartModule)
  },
  {
    path: 'billing-address',
    loadChildren: () => import ('@app/shipping-details/shipping-details.module').then((m) => m.ShippingDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-orders',
    loadChildren: () => import('@app/orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'incoming-orders',
    loadChildren: () => import('@app/incoming-orders/incoming-orders.module').then((m) => m.IncomingOrdersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-orders/order-details/:id',
    loadChildren: () => import('@app/order-detials/order-detials.module').then((m) => m.OrderDetialsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'incoming-orders/order-details/:id/:incomingOrder',
    loadChildren: () => import('@app/order-detials/order-detials.module').then((m) => m.OrderDetialsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'post',
    loadChildren: () => import('@app/create-post/create-post.module').then((m) => m.CreatePostModule),
    canActivate: [AuthGuard]
  },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
