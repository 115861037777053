export enum FlexMediaBreakpoints {
  xs = 0,
  sm = 1,
  md = 2,
  lg = 3,
  xl = 4,
  xxl = 5,
}

const breakpoints = {
  xs: 320,
  sm: 480,
  md: 600,
  lg: 840,
  xl: 960,
  xxl: 1280,
};

// subtract 0.1px from breakpoint instead of 1px to avoid issues when browser zoom causes sub-pixel width values
//  e.g: max-width 959px for lg, min-width 960px for xl, window width returns 959.5 and matches neither
//     -> max-width 959.9 should catch vast majority of values
//  NOTE: 0.001 is too small for Edge and causes false-positives (e.g. max-width 1279.999 active when window is 1280 @ zoom 150%)
//        also 0.01 causes issues for Safari at 600px
const mediaQueryMaxWidthOffset = 0.1;

const mediaqueries = {
  xs: breakpoints.xs,
  sm: breakpoints.sm,
  md: breakpoints.md,
  lg: breakpoints.lg,
  xl: breakpoints.xl,
  xxl: breakpoints.xxl,

  default: '(min-width: 1px)',
  defaultMax: '(max-width: ' + (breakpoints.xs - mediaQueryMaxWidthOffset) + 'px)',
  phone: '(min-width: ' + breakpoints.xs + 'px)',
  phoneMax: '(max-width: ' + (breakpoints.lg - mediaQueryMaxWidthOffset) + 'px)',
  tablet: '(min-width: ' + breakpoints.lg + 'px)',
  tabletMax: '(max-width: ' + (breakpoints.xl - mediaQueryMaxWidthOffset) + 'px)',
  desktop: '(min-width: ' + breakpoints.xl + 'px)',
  desktopMax: '(max-width: ' + (breakpoints.xxl - mediaQueryMaxWidthOffset) + 'px)',
  largeDesktop: '(min-width: ' + breakpoints.xxl + 'px)',
  isDefault: '',
  isPhone: '',
  isTablet: '',
  isDesktop: '',
  isLargeDesktop: '',
  isMobile: '',
  isNotMobile: '',
  breakpoint: null,
  flexBreakpoints: null,
  numericalBreakpoint: null,
  flex_xs: 'screen and (max-width: ' + (breakpoints.sm - mediaQueryMaxWidthOffset) + 'px)', // 0 - 479.9
  flex_gt_xs: 'screen and (min-width: ' + breakpoints.sm + 'px)', // 480+
  flex_sm: 'screen and (min-width: ' + breakpoints.sm + 'px) and (max-width: ' + (breakpoints.md - mediaQueryMaxWidthOffset) + 'px)', // 480 - 599.9
  flex_gt_sm: 'screen and (min-width: ' + breakpoints.md + 'px)', // 600+
  flex_md: 'screen and (min-width: ' + breakpoints.md + 'px) and (max-width: ' + (breakpoints.lg - mediaQueryMaxWidthOffset) + 'px)', // 600 - 839.9
  flex_gt_md: 'screen and (min-width: ' + breakpoints.lg + 'px)', // 840+
  flex_lg: 'screen and (min-width: ' + breakpoints.lg + 'px) and (max-width: ' + (breakpoints.xl - mediaQueryMaxWidthOffset) + 'px)', // 840 - 959.9
  flex_gt_lg: 'screen and (min-width: ' + breakpoints.xl + 'px)', // 960+
  flex_xl: 'screen and (min-width: ' + breakpoints.xl + 'px) and (max-width: ' + (breakpoints.xxl - mediaQueryMaxWidthOffset) + 'px)', // 960 - 1279.9
  flex_gt_xl: 'screen and (min-width: ' + breakpoints.xxl + 'px)', // 1280 +
};

mediaqueries['isDefault'] = mediaqueries.defaultMax;
mediaqueries['isPhone'] = mediaqueries.phone + ' and ' + mediaqueries.phoneMax;
mediaqueries['isTablet'] = mediaqueries.tablet + ' and ' + mediaqueries.tabletMax;
mediaqueries['isDesktop'] = mediaqueries.desktop + ' and ' + mediaqueries.desktopMax;
mediaqueries['isLargeDesktop'] = mediaqueries.largeDesktop;
mediaqueries['isMobile'] = mediaqueries.phoneMax;
mediaqueries['isNotMobile'] = mediaqueries.tablet;

export const mediabreaks = mediaqueries;
