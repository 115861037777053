  <div class="dasboard-custom">
    <div class="demo-grid-1 mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <div class="content-top-heading">
          <h3>Dashboard</h3>
        </div>
      </div>
    </div>
  
    <div class="demo-grid-1 mdl-grid">
      <div class="mdl-cell mdl-cell--3-col">
        <div class="content-top-heading">
          <div class="left-box">
            <div class="left-box-1">
              <img src="{{userData?.profilePicture || 'assets/images/profilepic2.png'}}" class="eshop_banner_img" alt="profilePicture" loading="lazy">
              <p class="m-profile-1">{{userData?.title +' '+ userData?.firstName +' '+ userData?.lastName | titlecase}}</p>
              <p class="m-profile-2">{{userData?.uniqueID |titlecase}}</p>
              <p class="m-profile-3">{{userData?.role |titlecase}}</p>
            </div>
            <div class="left-box-2">
              <img src="{{downloadQr || 'assets/images/barcode.png'}}" class="barcode" loading="lazy" alt="qrCode">
              <p class="barcode-12" (qClickKeydown)="openShareOptions(userData)" style="cursor: pointer;"><i class="fa-solid fa-share"></i> Share</p>
              <ul class="qr-ul">
                <li><button class="QR-1" (qClickKeydown)="downloadCode(userData)" [disabled]="loading" style="cursor: pointer;">Download</button></li>
                <li><button class="QR-1" (qClickKeydown)="openScanQrCode(userData)">Scan QR</button></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--9-col">
        <div class="content-top-heading">
          <div class="right-box">
            <div class="create-search">
              <ul class="create-search-ul">
                <li><button class="create-search-bt" (qClickKeydown)="openCreateOptions()" style="cursor: pointer;">Create</button></li>
                <li class="reltive-bn"><i class="fa-solid fa-magnifying-glass"></i><input type="search" placeholder="Search"></li>
              </ul>
              <div class="brodcast-search-12">
                <p class="brodcast-search-12-p"><a href="javascript:void(0)" routerLink="/post" style="cursor: pointer;color: #43425d;"><i class="fa-solid fa-pen-to-square"></i> Start a post</a></p>
                <ul class="brodcast-search-ul">
                  <li><a href="javascript:void(0)" (click)="openImageFileInput()"><i class="fa-solid fa-image"></i> Photo</a>
                  <input type="file" #imageFileInput style="display: none;" accept="image/jpeg" (change)="handleImageFileChange($event)">
                  <img *ngIf="selectedImage" [src]="selectedImage" loading="lazy" alt="Selected Image" style="width: 100px;">
                  </li>
                  <li><a href="javascript:void(0)" (click)="openVideoFileInput()"><i class="fa-solid fa-video"></i> Video</a>
                  <input type="file" style="display: none;" #videoFileInput accept="video/mp4" (change)="handleVideoFileInput($event)">
                  <video *ngIf="selectedVideo" [src]="selectedVideo" alt="Selected Image" controls autoplay style="width: 300px;"></video>
                  </li>
                  <li><a href="javascript:void(0)" (click)="showDate = true; showArticle = false"><i class="fa-solid fa-calendar-days"></i> Event</a>
                    <mat-form-field class="example-form-field" *ngIf="showDate">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="datepicker">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #datepicker>
                          <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                      </mat-form-field>
                </li>
                  <li><a href="javascript:void(0)" (click)="showArticle = true; showDate = false"><i class="fa-solid fa-newspaper"></i> Write Article</a>
                    <mat-form-field class="example-full-width" *ngIf="showArticle">
                      <mat-label>Write your Article here</mat-label>
                      <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
                      <button mat-raised-button color="primary">Apply</button>
                    </mat-form-field>
                  </li>
                </ul>
              </div>
              <div class="activity">
                <p class="activity-p">Activity</p>
                <div class="activity-flex">
                  <p class="Write-Article"><b>Write Article Write Article Write</b> Article Write Article</p>
                  <a class="Write-Article-a dot-1" href="javascript:void(0)"><span></span><span></span><span></span></a>
                </div>
                <div class="activity-flex-1">
                  <img src="assets/images/eshop_banner.png" loading="lazy" alt="profilePicture">
                  <div class="activity-flex-12">
                    <p class="JohnDeo">John Deo</p>
                    <p class="JohnDeo-p">Write Article Write Article Write</p>
                  </div>
                </div>
                <p class="readable-p">It is a long established fact that a reader will be distracted by the readable content</p>
                <p class="readable-p">It is a long established fact that a reader will be distracted by the readable content</p>
                <div class="readable-flx"><a class="Write-Article-a" href="javascript:void(0)"><span></span><span></span><span></span> <p>See more</p></a></div>
                <div class="heart">
                  <ul>
                    <li><i class="fa-solid fa-thumbs-up"></i></li>
                    <li><i class="fa-solid fa-heart"></i></li>
                    <li><p>107 - 24 Comments</p></li>
                  </ul>
                </div>
                <div class="cmnts">
                  <ul class="brodcast-search-ul">
                    <li><a href="javascript:void(0)"><i class="fa-solid fa-thumbs-up"></i> Like</a></li>
                    <li><a href="javascript:void(0)"><i class="fa-solid fa-share"></i> Share</a></li>
                    <li><a href="javascript:void(0)"><i class="fa-solid fa-paper-plane"></i> Send</a></li>
                  </ul>
                  <a class="chevron-down" href="javascript:void(0)"><i class="fa-solid fa-chevron-down"></i></a>
                </div>
                <p class="Most_Relavent">Most Relavent <i class="fa-solid fa-caret-down"></i></p>
                <div class="Most_Relavent_ui">
                  <img class="kloy" src="assets/images/eshop_banner.png" loading="lazy" alt="review">
                  <div class="Most_Relavent_ui_1">
                    <p class="Most_Relavent_p">It is a long established</p>
                    <p class="Most_Relavent_p_1">It is a long established</p>
                    <p class="Most_Relavent_p_2">It is a long established</p>
                    <p class="Most_Relavent_p_3">43 m<a class="Write-Article-a" href="javascript:void(0)"><span></span><span></span><span></span></a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<ng-container *ngIf="loading">
  <div class="spin">
    <mat-spinner>Downloading...</mat-spinner>
  </div>
</ng-container>

<div class="contBlock" #contentToConvert id="contentToConvert" style="display: flex;flex-direction: column;align-items: center;margin-top: -200000px; position: absolute;padding: 10px 0;">
  <img src="assets/logo.png" alt="logo" aria-label="logo" style="max-width: 100px;margin-bottom: 10px;"/>
  <img src="{{downloadQr}}" alt="qrCode" aria-label="qrCode" class="qrimg">
  <a class="qrName" href="javascript:void(0)">{{download?.firstName + ' ' + download?.lastName |titlecase}} ({{download?.uniqueID}})</a>
</div>

<!-- <div class="contBlock" #contentToConvert id="contentToConvert" style="position: absolute; width: 60%; margin-top:-20000px;">
  <img src="assets/logo.png" style="max-width: 100px;margin-bottom: 10px;"/>
  <img src="{{downloadQr}}" alt="qrCode" class="qrimg">
  <a class="qrName" href="javascript:void(0)" *ngIf="download">{{download?.firstName + ' ' + download?.lastName |titlecase}} ({{download.uniqueID}})</a>
</div> -->