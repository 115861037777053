import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EshopService } from '../../../eshop/eshop.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NotificationService } from '@app/services/notification.service';
import { NetworkServiceService } from '@app/services/network-service.service';
import { ShareOptionsComponent } from '@app/share-options/share-options.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { Location } from '@angular/common';
import html2canvas from 'html2canvas';
import { EShopInfo } from '@app/models/eshop.model';
import { ListingService } from '@app/listing/listing.service';
import jsPDF from 'jspdf';
import { SessionService } from '@app/services/session.service';

@Component({
  selector: 'app-estore-qr-code',
  templateUrl: './estore-qr-code.component.html',
  styleUrls: ['./estore-qr-code.component.scss']
})
export class EstoreQrCodeComponent implements OnInit, OnDestroy {
  veiwQRUrl:string='https://api.qrserver.com/v1/create-qr-code/?size=85x85&data=https://myqlout.com/eshopQr/'
  displayedColumns: string[] = ['select','QR Code', 'Name', 'Contact', 'Share', 'Options'];
  dataSource = new MatTableDataSource<any>();
  private destroy$: Subject<void> = new Subject();
  selection = new SelectionModel<any>(true, []);
  publicList:any;  openingHours: any[] = [];  picUrl:any;  estoreListing: any;  estoreTimimg: any;  ShowlistingArray: any=[]=[];
  title = 'QLOUT';
  currentUrl: any;  lat: any;  lng: any;  deviceInfo: any;  objs: any;  user: any;  user_id: any;
  @ViewChild('contentToConvert') contentToConvert!: ElementRef;
  @ViewChild('myGoogleMap', { static: false })
  map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false })
  info!: MapInfoWindow;
  zoom = 12;  maxZoom = 20;  minZoom = 5;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,scrollwheel: true,disableDoubleClickZoom: false,
    mapTypeControlOptions: {mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID, google.maps.MapTypeId.SATELLITE]}, // here´s the array of controls
    disableDefaultUI: true, // a way to quickly hide all controls
    mapTypeControl: true,streetViewControl: false,fullscreenControl: true,rotateControl: false,
    mapTypeId: 'roadmap',maxZoom:this.maxZoom,minZoom:this.minZoom,
  }
  markers = []  as  any; infoContent = ''
  download: any; loading: boolean=false; addressHead: any;
  address1: any; address2: any; address3: any; address4: any; address5: any;
  currentUser: any;notPublish:boolean = false;qrCode: string;downloadQr: string;userData: any;prmId: string;isFollowingCurrentList: boolean;

  constructor(private eshopService: EshopService, private location: Location,private route: ActivatedRoute,private api: ApiService,private networkService:NetworkServiceService, private renderer: Renderer2,public session: SessionService,private el: ElementRef,private listingService:ListingService,private router:Router,private notificationService:NotificationService,public dialog: MatDialog,private http:HttpClient,private deviceService: DeviceDetectorService,private NgxXml2jsonService:NgxXml2jsonService) {
      this.route.params.subscribe(res=>{
        if(res?.id) {
        const param = atob(res.id);
        this.prmId = param;
        this.getListing(param)
        }
      })
    // const param = atob(this.route.snapshot.params.id);
    // this.getListing(param)
    this.user_id = JSON.parse(sessionStorage.getItem('user-id'))
    this.user = JSON.parse(localStorage.getItem('user'))
    this.currentUser = this.user
    this.currentUrl = window.location.href
  }

  ngOnInit() {}

  openShareOptions(data) {
    data.shareUrl = location.origin+`/eshopQr/`+btoa(`${data?.eStore?.id}`);
    data.type = 'eshopQr';
    this.dialog.open(ShareOptionsComponent, {width: '500px',height:'150px',data: data,disableClose: true});
  }

  deleteEshop(e) {
    this.eshopService.deleteEshop(e.eStore.id).pipe(takeUntil(this.destroy$)).subscribe((res) => {this.dataSource = new MatTableDataSource<any>(res);});
  }

  goToList(id:any) {
    if(this.user_id || this.user) {
      this.router.navigate([`/qtag/view/`+btoa(`${id}`)])
    }else {
      this.notificationService.showErrorNotification('Please Login First To See the Product Details',1000)
    }
  }

  getListing(data?) {
    this.loading = true;
    this.api.publicEstore(data).subscribe((res: any) => {
      if (res.msg === 'Estore not published by owner' || res.eStore.publish == 'draft') {
        this.notificationService.showErrorNotification('Estore not published by owner', 1000)
        this.notPublish = true; this.loading = false;
        return true;
      } else {
        if (res.length > 5) {
          this.estoreListing = res.length;
          this.ShowlistingArray = res.listing.filter(i=> {if(i.user == this.currentUser?.id){return i;}else
          return i.user != this.currentUser?.id && i.isApproval == 'approved' && i.block == 'no'}).slice(0, 5);
        } else {
          this.estoreListing = res.listing.filter(i=> i.block == 'no');
          this.ShowlistingArray = res.listing.filter(i=> {if(i.user == this.currentUser?.id){return i;}else
            return i.user != this.currentUser?.id && i.isApproval == 'approved' && i.block == 'no'})
        }
        this.publicList = res;
        this.getUserById(this.publicList?.eStore?.user)
        this.estoreListing = res.listing;
        let unique:any[]=[]; const counts:any = []=[];
        this.estoreListing.filter((i:any)=>{
          if(!unique.includes(i.lat)){
            this.estoreListing.forEach(function (x) { counts[x.lat] = (counts[x.lat] || 0) + 1; }); //finds the counts of scans in all locations 
            unique.push(i.lat) // find the unique value
            const resultArray = unique.map(key => ({lat: key,value: counts[key] || 0 /*Use 0 if the key is not found in counts*/}));
            const matchedEntry = resultArray.find(entry => entry.lat === i.lat);
            // Map the value to unique[index] if a matching entry is found
            if (matchedEntry) {i.value = matchedEntry.value;}
            this.dropMarker('',i)
            return i 
          }
        })
        if(this.ShowlistingArray.length > 5) 
        this.showMore();
        this.qrCode = this.veiwQRUrl+btoa(this.publicList?.eStore?.id);
        this.estoreTimimg = res.workingHours[0]?.hours;
        this.picUrl = this.publicList.eStorePhoto[0]?.photoUrl;
        this.addressHead = this.publicList?.eStore?.address?.split(', ');
        if(this.addressHead){
          this.address1 = this.addressHead[0] || '';this.address2 = this.addressHead[1] || '';this.address3 = this.addressHead[2] || '';this.address4 = this.addressHead[3] || '';this.address5 = this.addressHead[4] || '';
        }
        if (this.publicList?.eStore?.location?.lat || this.publicList?.eStore?.location?.long) {
          this.center = {lat: Number(this.publicList?.eStore?.location?.lat),lng: Number(this.publicList?.eStore?.location?.long),};
          this.dropMarker(this.center,'')
        }
        if (this.listingService.products.length > 0) {
          this.ShowlistingArray.map(element => {
            element.addedInCart = false;
            this.listingService.products.forEach(prd => {
              if(prd.estoreId){if (element._id == prd.listingId && this.publicList?.eStore?.id == prd.estoreId) {element.addedInCart = true;}}
            })
          });
        }
        this.api.getDeviceInfoSaved('eStore', res?.eStore?.id, this.user);
      }
    },error => {this.loading = false;})
  }

  getUserById(id) {this.api.getUserProfile(id).subscribe((res:any) => {this.userData = res;this.loading = false;},error => {this.loading = false;})}

  navigateProfile() {this.router.navigate(['profile/Profile-view',btoa(`${this.userData.uniqueID}`)])}

  getLatLng(id:any) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          setTimeout(() => {this.getLocation(id)}, 1000);
        }
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  
  getLocation(id:any){
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const url = 'https://us1.locationiq.com/v1/reverse.php';
    const apiKey = 'pk.729e8db921e5c59f0fe66d2878610859';
    let queryParams = {key: apiKey, lat: this.lat,lon: this.lng};
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('noAtuh', 'true');
    this.http.get(`https://us1.locationiq.com/v1/reverse.php?key=${apiKey}&lat=${queryParams.lat}&lon=${queryParams.lon}`,{headers:httpHeaders, responseType: 'text' }).subscribe((response:any) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(response, 'text/xml');
        const obj = this.NgxXml2jsonService.xmlToJson(xml);
        this.objs = obj;
        this.api.getDeviceInfoSaved('eshop',id,this.user);
      },err=>{});
  }

  saveAnalytic(id:any,obj:any){
    let body={
      latitude:this.lat,
      longitude:this.lng,
      product_id:id,
      browser:this.deviceInfo.browser,
      device:this.deviceInfo.deviceType,
      operating_system:this.deviceInfo.os,
      address:obj.reversegeocode['result'],
      repeat:this.user_id ? true : false,
      gender:this.user.gender ?? ''
    }
    this.api.SaveLocation(body).subscribe(res=>{})
  }

  back() {this.location.back()}

  followButton(data) {
    if(this.user){
      let body = {eStore: data.eStore._id ?? data.eStore.id}
      this.api.followEstore(body).subscribe(res => {this.getListing(this.prmId);this.getFollowingLists();})
    }
    else{
      this.notificationService.showErrorNotification('Please login first',1000)
      setTimeout(()=>{this.router.navigate(['login'])},1000)
    }
  }

  unFollowButton(data) {
    let body = {eStore: data.eStore._id ?? data.eStore.id}
    this.api.unfollowEstore(body).subscribe(res => {this.getListing(this.prmId);this.getFollowingLists();})
  }

  navigateChat(data?,item?) {
    if(data == 'eshop') {
      this.router.navigate([`/chat/chat_id/`+btoa(`${this.publicList?.eStore?.id ?? this.publicList?.eStore?._id}`) +'/'+ btoa(`estore`)])
    } else if(data == 'qtag') {
      this.router.navigate(['/chat/'+btoa(`${item?.id ?? item?._id}`) +'/'+ btoa(`qtag`)])
    }
  }

  getFollowingLists(){
    this.api.followingEstore().subscribe((res)=>{
      if (res) {
        const allFollowingLists = res;
        const followingList = allFollowingLists.filter((i)=> i?.id === this.publicList?.eStore?.id);
        if(followingList.length > 0){this.isFollowingCurrentList = true;}
        else{this.isFollowingCurrentList = false;}
      }
    },error=>{console.error('Something not well in following list')})
  }

  showMore(){this.ShowlistingArray = this.estoreListing.slice(0,this.ShowlistingArray.length + 5)}

  logSelection() {this.selection.selected.forEach((s:any) => {});}

  eventHandler(event: any ,name:string){
    // Add marker on double click event
    if(name === 'mapDblclick'){/*this.dropMarker(event)*/}
  }

  dropMarker(event: any,i:any) {
    this.markers.push({
      position: {
        lat: i ? Number(i?.lat) : event ? Number(event?.lat) : event.latLng.lat(),
        lng: i ? Number(i?.lng) : event ? Number(event?.lng) : event.latLng.lng(),
      },
      label: {
        color: '#7e7ef1',
        // text: this.publicList.eStore.address,
      },
      title: i ? i.address : this.publicList?.eStore?.address,
      info: `${i ? 'Qtag Address: '+i.address : 'Eshop Address: '+this.publicList?.eStore?.address}`,
      options: {
        animation: google.maps.Animation.DROP,
      },
    })
  }

  openInfo(marker: MapMarker, content: string) {this.infoContent = content;this.info.open(marker)}

  copyInfoUrle(data){this.networkService.copyInfoUrle(data);this.notificationService.showSuccessNotification('Link copied to clipboard',1000);}

  async downloadCode(e: EShopInfo) {
    this.download = e.eStore;
    this.loading = true;
    await new Promise(resolve => setTimeout(resolve, 1000));
    this.convertAndDownload()
  }

  async convertAndDownload(): Promise<void> {
    try {
        const content: any = this.el.nativeElement.querySelector('#contentToConvert');
        /*Capture the content as a canvas using html2canvas*/ const canvas = await html2canvas(content, { useCORS: true, allowTaint: true, scale: window.devicePixelRatio, logging: false });
        /*Convert canvas to data URL*/const imageData = canvas.toDataURL('image/jpeg',0.8);
        /*Create a download link*/ const downloadLink = document.createElement('a');
        downloadLink.href = imageData;
        downloadLink.download = `${this.download?.businessName} (${this.download?.estoreUniqueID}).jpg`;
        /*Trigger download immediately*/downloadLink.click();
        /*Clean up after download*/this.loading = false;
    } catch (error) {
        console.error('Error during conversion and download:', error);
        this.loading = false;
    }
}


  ngOnDestroy(): void {this.destroy$.next();this.destroy$.complete();}

  addToCart(data: any, list: any) {
    if (this.listingService.products.length > 0) {
      var estoreIdFind =this.listingService.products.filter(i =>i.estoreId);
      var estore = estoreIdFind.some(i =>i.estoreId !== atob(list?.eStore?.id));
      var listingName = this.listingService.products.some(i => i.user !== data.user);
      var listingId = this.listingService.products.some(i => i.listingId == (data._id || data.id));
      if (estore) {this.notificationService.showErrorNotification('You have an item from another provider in your cart.', 2000);return}
      else if (listingId) {
        this.notificationService.showErrorNotification('You have already added this product in your cart',2000);
        return;
      }
      // else if (listingName) {this.notificationService.showErrorNotification('You have an item from another provider in your cart.', 2000);return}
      else {
        let body = {
          listingId: data.id ?? data._id,
          listingName: data.listingName,
          "estore": "",
          price: data.price !== "undefined" ? data.price : 0,
          user: data.user,
          userId: this.currentUser?.id,
          select: false,
          quantity: 1,
          percentage: data.percentage,
          estoreName: list?.eStore?.businessName,
          estoreOwnerId: list?.eStore?.user,
          estoreId: atob(list?.eStore?.id)
        }
        this.listingService.addItemToCart(body);
      }
    }
    else {
      let body = {
        listingId: data.id ?? data._id,
        listingName: data.listingName,
        "estore": "",
        price: data.price !== "undefined" ? data.price : 0,
        user: data.user,
        userId: this.currentUser?.id,
        select: false,
        quantity: 1,
        percentage: data.percentage,
        estoreName: list?.eStore?.businessName,
        estoreOwnerId: list?.eStore?.user,
        estoreId: atob(list?.eStore?.id)
      }
      this.listingService.addItemToCart(body);
    }
  }
}
