import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResetPasswordRoutingModule } from './reset-password-routing.module';
import { ResetPasswordComponent } from './reset-password.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material/material.module';
import { ClickKeydownModule } from '@app/utils/click-keydown/click-keydown.module';


@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [
    CommonModule,
    ResetPasswordRoutingModule,
    MaterialModule,ClickKeydownModule, FlexLayoutModule, ReactiveFormsModule
  ],
  exports: [ResetPasswordComponent]
})
export class ResetPasswordModule { }
