import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteDataService {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  get routeData$(): Observable<any> {
    return this.router.events.pipe(filter((event) => event instanceof NavigationEnd),switchMap(() => {
        if (this.activatedRoute?.firstChild) {
          return observableOf(this.activatedRoute.snapshot.firstChild.data);
        } else {
          return observableOf(null);
        }
      })
    );
  }
}
