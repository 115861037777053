<div fxLayout="row" fxLayoutAlign="center" class="container" ngClass.gt-md="container-desktop">
  <img class="background-img" src="/assets/logo_bg_210.svg" alt="logo" loading="lazy">
  <div fxFlex.gt-md="40%" fxLayoutAlign="center center">
    <mat-card fxLayout="column" fxLayoutAlign="center">
      <img src="/assets/logo.svg" alt="logo" aria-label="logo" loading="lazy"/>
      <h1>Enter your new password to reset</h1>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPasswordSubmit()" fxLayout="column" fxLayoutAlign="center">
        <mat-form-field appearance="fill">
          <mat-label>Enter New Password</mat-label>
          <input matInput [type]="hide1 ? 'password' : 'text'" formControlName="password" autocomplete="current-password" required />
          <button mat-icon-button matSuffix (qClickKeydown)="hide1 = !hide1" [attr.aria-label]="hide1 ? 'Show password' : 'Hide Password'">
            <mat-icon>{{ hide1 ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="password.invalid">{{ getPasswordErrorMessage() }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Confirm Password</mat-label>
          <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="confirmPassword" autocomplete="current-password" required />
          <button mat-icon-button matSuffix (qClickKeydown)="hide2 = !hide2" [attr.aria-label]="hide2 ? 'Show password' : 'Hide Password'">
            <mat-icon>{{ hide2 ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="confirmPassword.invalid">{{ getConfirmPasswordErrorMessage() }}</mat-error>
        </mat-form-field>
        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" class="button-group">
          <button mat-flat-button color="primary" routerLink="/">Cancel</button>
          <button mat-flat-button color="warn" type="submit" [disabled]="!resetPasswordForm.valid">Confirm</button>
        </div>
      </form>
    </mat-card>
  </div>
  <div fxHide fxShow.gt-md fxFlex="60%" class="image-container"></div>
</div>
