export function copyTextAreaToClipBoard(message: string) {
    // const cleanText = message.replace(/<\/?[^>]+(>|$)/g, '');
    const cleanText = message
    const x = document.createElement('TEXTAREA') as HTMLTextAreaElement;
    x.value = cleanText;
    document.body.appendChild(x);
    x.select();
    document.execCommand('copy');
    document.body.removeChild(x);
  }

  export function copyTextAreaToClipBoardLink(message: string) {
    const cleanText = message.replace(/<\/?[^>]+(>|$)/g, '');
    const x = document.createElement('TEXTAREA') as HTMLTextAreaElement;
    x.value = cleanText;
    document.body.appendChild(x);
    x.select();
    document.execCommand('copy');
    document.body.removeChild(x);
  }