import { BreakPoint, BREAKPOINTS } from '@angular/flex-layout';
import { mediabreaks } from './config.mediabreaks';

/* The "lt-*" Responsive breakpoints were explicitly excluded from the custom breakpoints to encourage a Mobile-First implementation */
const CUSTOM_BREAKPOINTS: BreakPoint[] = [
  {
    alias: 'xs',
    suffix: 'Xs',
    overlapping: false,
    mediaQuery: mediabreaks.flex_xs,
  },
  {
    alias: 'gt-xs',
    suffix: 'GtXs',
    overlapping: true,
    mediaQuery: mediabreaks.flex_gt_xs,
  },
  {
    alias: 'sm',
    suffix: 'Sm',
    overlapping: false,
    mediaQuery: mediabreaks.flex_sm,
  },
  {
    alias: 'gt-sm',
    suffix: 'GtSm',
    overlapping: true,
    mediaQuery: mediabreaks.flex_gt_sm,
  },
  {
    alias: 'md',
    suffix: 'Md',
    overlapping: false,
    mediaQuery: mediabreaks.flex_md,
  },
  {
    alias: 'gt-md',
    suffix: 'GtMd',
    overlapping: true,
    mediaQuery: mediabreaks.flex_gt_md,
  },
  {
    alias: 'lg',
    suffix: 'Lg',
    overlapping: false,
    mediaQuery: mediabreaks.flex_lg,
  },
  {
    alias: 'gt-lg',
    suffix: 'GtLg',
    overlapping: true,
    mediaQuery: mediabreaks.flex_gt_lg,
  },
  {
    alias: 'xl',
    suffix: 'Xl',
    overlapping: false,
    mediaQuery: mediabreaks.flex_xl,
  },
  {
    alias: 'gt-xl',
    suffix: 'GtXl',
    overlapping: true,
    mediaQuery: mediabreaks.flex_gt_xl,
  },
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINTS,
  useValue: CUSTOM_BREAKPOINTS,
};
