import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadWalletComponent } from './load-wallet.component'
const routes: Routes = [
  {path: '', component: LoadWalletComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoadWalletRoutingModule { }
