import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { retry, catchError, finalize, tap, map } from 'rxjs/internal/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse} from "@angular/common/http";


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    let token=""
    if(localStorage.getItem('access_token')){
       token= localStorage.getItem('access_token').replace(/^"(.*)"$/, '$1')
    }
    // add Authorization token to header
    if(!request.headers.has('noAtuh')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    } else {
      request = request.clone({
        headers: request.headers.delete('noAtuh')
      });
    }

      return next.handle(request).pipe(map((e: HttpEvent<any>) => {
        if (e instanceof HttpResponse) {
            if (e.status === 401) {
                throw new Error('invalid token');
            }
        }
        return e;
    }));
  }
}
