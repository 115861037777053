import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccessNotification(message: string, duration: number = 1000) {
    this.showNotificationBar(message, 'mat-snack-bar-container-success', duration);
  }

  showErrorNotification(message: string, duration?: number) {
    this.showNotificationBar(message, 'mat-snack-bar-container-error', duration);
  }

  showNotificationBar(message: string, panelClass: string, duration?: number) {
    this.snackBar.open(message, 'X', {
      duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass,
    });
  }
}
