import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ListingService } from '@app/listing/listing.service';
import { VerifyEmailPopupComponent } from '@app/unauthenticated/verify-email-popup/verify-email-popup.component';
import { LocalStorageNames } from '@constants/local-storage-constants';
import { SessionStorageNames } from '@constants/session-storage-constants';
import { UserLogin } from '@models/signin.model';
import { ApiService } from '@services/api.service';
import { LocalStorageService } from '@services/local-storage.service';
import { NotificationService } from '@services/notification.service';
import { SessionStorageService } from '@services/session-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public authTokenSubject: BehaviorSubject<string>;
  public authToken: Observable<string>;
  redirectToCheckout:boolean=false;
  public loading: boolean;

  constructor(private router: Router,private route: ActivatedRoute,private localStorageService: LocalStorageService,private sessionStorageService: SessionStorageService,private apiService: ApiService,private notificationService: NotificationService,public dialog: MatDialog,private activateRoute:ActivatedRoute,private listingService:ListingService) {
    this.authTokenSubject = new BehaviorSubject<string>(this.localStorageService.get(LocalStorageNames.accessToken));
    this.authToken = this.authTokenSubject.asObservable();
    this.activateRoute.queryParams.subscribe((params) => {
      if(params){
        this.redirectToCheckout = params['checkout'];
      }
    });
  }

  loginUser(userLogin: UserLogin, isRedirect:boolean) {
    this.loading = true;
    return this.apiService.logIn(userLogin).pipe(first()).subscribe((res) => {
          const token = res?.jwtToken;
           if(!res?.account?.verified){
            this.dialog.open(VerifyEmailPopupComponent, {
              width: '600px',
              height:'800px',
              data: userLogin,
              disableClose: true
            });
            this.dialog.afterAllClosed.subscribe((res)=> {
              this.loading = false;
            })
            this.loading = false;
            return
           }else{
            this.localStorageService.set(LocalStorageNames.accessToken, token);
            this.localStorageService.set(LocalStorageNames.user, res?.account);
           this.sessionStorageService.set(SessionStorageNames.userId, res?.account?.id);
            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
            if(this.redirectToCheckout){
              this.router.navigate(['/cart']);
            }else{
               if(res.account.firstLogin == "1" || res.account.firstLogin == "0") {
              this.router.navigate(['/profile/edit'])
             }
             else if(res.account.firstLogin == "2") {
              if( !isRedirect ){
                this.router.navigate(['/dashboard']);
              }else{
                this.router.navigate(['/wallet'])
              }
             }
             this.loading  = false;
            } 
             this.authTokenSubject.next(token);
             this.listingService.checkCartandUpdate();
           }
        },(error) => {
          this.loading = false;
          this.notificationService.showErrorNotification(error,1000);
        }
      );
  }

  logout() {
    this.destroyStorageInfo();
    this.router.navigate(['/']);
    this.authTokenSubject.next(null);
    this.loading = false;
    sessionStorage.clear()
    // localStorage.clear()
  }

  get authTokenValue() {
    return this.authTokenSubject.value;
  }

  private destroyStorageInfo() {
    this.loading = false;
    Object.values(LocalStorageNames).map((value) => {
      this.localStorageService.remove(value);
    });
    Object.values(SessionStorageNames).map((value) => {
      this.sessionStorageService.remove(value);
    });
  }
}
