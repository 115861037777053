import { Component, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from '@app/services/api.service';
import { SessionService } from '@app/services/session.service';
import { Menu, MenuItemsService } from '@services/menu-items.service';
import { RouteDataService } from '@services/route-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit {
  menuItems: Menu[];  mode = 'side';
  isopened: boolean = true;  showNav: boolean = false;
  isAuthRoute$: Observable<boolean>;
  private isMobile: boolean;  role: any;  token:any;  currentRouteBase:any = '';
  noHeaderRoutes=['cart','qtagQr','eshopQr']
  roles: any;  menu: any[]=[];  currentUser: any;

  constructor(
    private sessionService: SessionService,  private apiService: ApiService,public menuItemsService: MenuItemsService,private _router:Router ,public mediaObserver: MediaObserver, private routeDataService: RouteDataService) {
    this.isMobile = this.mediaObserver.isActive('xs') || this.mediaObserver.isActive('sm') || this.mediaObserver.isActive('md');
    if (this.isMobile) {this.mode = 'over';this.isopened = false;}
    let d = localStorage.getItem('user');
    this.currentUser = JSON.parse(d);
    let role = this.currentUser?.role == 'Admin' ? 'admin' : this.currentUser?.role == 'User' ? 'user' : 'manager';
    this.getRoles(role);
    this.sessionService.authTokenSubject.subscribe((res) => {
      this.token = res;
      if(res) {
        setTimeout(() => {let unAUthUrl = ['/sign-up', '/reset-password', '/forgot-password'];if(unAUthUrl.includes(this._router.url)) {this._router.navigate(['dashboard'])}this.getMenus();}, 100);
      } else {this.menuItems = [];}
    })
    this._router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
      let rr= this._router.url.split('/');
        this.currentRouteBase= rr[1];
      }});
  }

  ngOnInit(): void {}

  getRoles(data?) {this.apiService.getRolePermission(data).subscribe((res: any) => {this.roles = res;});}

  onClick(data){this._router.navigate([`/${data}`]);this.toggle();}

  getMenus() {
    this.menuItems = this.menuItemsService.getMenuitems();
    if(this.roles){
      Object.keys(this.roles).forEach((state) => {
        // Find the corresponding menu item
        const menuItem = this.menuItems.find((item) => item.permission === state);
        // Update the 'enable' property based on the permission value
        if (menuItem) {
          menuItem.enable = this.roles[state] === '1';
        }
      });
    }
    this.isAuthRoute$ = this.routeDataService.routeData$.pipe(map((data) => !data?.isUnuathRoute || null));
  }

  toggle() {if (this.isMobile) {this.isopened = !this.isopened;}}
}
