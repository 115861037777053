import { AfterViewInit, Component, Inject, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from '@app/services/api.service';
import { NotificationService } from '@app/services/notification.service';
import { NgxScannerQrcodeComponent, ScannerQRCodeDevice, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-scan-modal',
  templateUrl: './scan-modal.component.html',
  styleUrls: ['./scan-modal.component.scss']
})
export class ScanModalComponent implements AfterViewInit {
  @ViewChild('action') action!: NgxScannerQrcodeComponent;
  @ViewChild('sendPopup', { static: false }) sendPopup: any;
  @ViewChild('confirmatipnPopup', { static: false }) confirmatipnPopup: any;
  url: string;  isFlashSupported:boolean;  isPause:boolean;  page: string;  qrCode: string;
  form!: UntypedFormGroup;  currentUser: any;  showTab: boolean = true;  searchStr: any;
  users: any[]=[];  filteredUsers: any[]=[];  walletPage: boolean = false;
  loading: boolean;

  constructor( private router: Router,private apiService: ApiService,private notificationService: NotificationService,public dialog: MatDialog, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if(this.router.url === '/wallet') {this.walletPage = true;}
    let d = JSON.parse(localStorage.getItem('user'));
    this.currentUser = d;
    this.isFlashSupported = this.checkFlashSupport();
    this.page = data.page;
    this.form = new UntypedFormGroup({
      reciever: new UntypedFormControl(''),
      sender: new UntypedFormControl(''),
      amount: new UntypedFormControl(null,[Validators.required]),
      uniqueID: new UntypedFormControl(''),
      comment: new UntypedFormControl('')
    })
    this.getAllUser();
  }

  ngAfterViewInit(): void {
    this.action.isReady.pipe(delay(1000)).subscribe(() => {this.handle(this.action, 'start');});
  }

  getAllUser() {
    this.apiService.getAllUsers().subscribe((res) => {
      let data = res.filter(i=>i.role && i.role !='Admin' && i.id != this.currentUser.id)
      this.users = data.map(i=>{i.firstName = i.firstName.toLowerCase();return i});
      this.filteredUsers = this.users;
    });
  }

  searchList(event,searchStr) {
      if(event.target.value || searchStr) {
      let d = this.users.filter((i:any) => {
        // Use '==' or '===' for comparison, and return true or false
          return (event.target.value === i.firstName || i.firstName.includes(searchStr) || event.target.value === i.uniqueID || i.uniqueID.includes(searchStr) || event.target.value === i.email || i.email.includes(searchStr))
      });
      this.filteredUsers = d;
    }
    else{this.filteredUsers = this.users;}
  }

  openUser(item) {
    this.form.patchValue({uniqueID:item.uniqueID,reciever:item.uniqueID,sender:this.currentUser.uniqueID})
    this.dialog.open(this.sendPopup, {width: '600px',height: '300px',disableClose: true});
  }

  close() {
    if(this.action) {this.action.stop();}
    this.dialogRef.close({scanned : ''});
    this.action.isStart = false;
    this.action.isPause = false;
    this.action.isBeep = false;
    this.action.isTorch = false;
    this.dialogRef.close()
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    if(this.page == 'wallet') {
    let url = e[0].value.split('.com/')
    let id = atob(url[1])
    this.form.patchValue({
      uniqueID:id.split('/')[0],
      reciever:id.split('/')[0],
      sender:this.currentUser.uniqueID
    })
    if(action) {
      action.pause(); // Detect once and pause scan!
      setTimeout(() => {action.stop();}, 300);
    }
     this.dialog.open(this.sendPopup, {
      width: '600px',
      height: '300px',
      disableClose: true
    });
    } else {
      this.url = e[0].value;
      if(e?.length) {
        if(action) {
          action.pause(); // Detect once and pause scan!
          setTimeout(() => {
            action.stop();
            // this.dialogRef.close({ scanned: e[0]?.value || ''});
          }, 300);
        }
      }
      window.location.href = this.url
    }
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: ScannerQRCodeDevice[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => alert);
    } else if( fn === 'stop' && this.isPause === true ){
      const fn1 = 'play';
      const fn2 = 'stop';
      action[fn1]().subscribe((r: any) => alert);
      setTimeout(()=>{
        action[fn2]().subscribe((r: any) => alert);
      },100)
      this.isPause = false;
    }
    else{
      if( fn === 'pause' ){
        this.isPause = true;
      }
      else{
        this.isPause = false;
      }
      action[fn]().subscribe((r: any) => alert);
    }
  }

  private checkFlashSupport(): boolean | undefined {
      const nav = window.navigator as any;
      const plugins = nav.plugins;

      for (let i = 0; i < plugins.length; i++) {
        const plugin = plugins[i];
        if (plugin.name.toLowerCase().indexOf('flash') !== -1) {
          return true;
        }
      }
    return false;
  }

  openConfirm() {
    Object.values(this.form.controls).forEach(control => {
      control.markAsTouched();
    });
    if(this.form.invalid) {
      this.notificationService.showErrorNotification('Please fill all the required fields',1000)
       return;
    }
    this.dialog.open(this.confirmatipnPopup, {
      width: '500px',
      height: '250px',
      disableClose: true
    });
    this.dialog.afterAllClosed.subscribe(res=>{
      this.showTab = true;
    })
  }

  get amountField() {
    return this.form.get('amount');
  }

  checkError(controlName: string, errorName: string) {
    return this.form.controls[controlName].hasError(errorName);
 }

  next() {this.showTab = false}
  previous() {this.showTab = true}

  submitData() {
    this.loading = true;
    let body = {
      uniqueID:this.form.value.uniqueID,
      amount: this.form.value.amount,
      remark: this.form.value.comment
    }
    
    this.apiService.ptpTransfer(body).subscribe((res:any)=>{
      if(res.msg == "Receiver's wallet is disabled. Transfer cannot be completed.") {
        this.notificationService.showErrorNotification(res.msg,2000);
        this.loading = false;
        this.dialog.closeAll();
      }
      if (res.msg == "Transfer succeess") {
        this.loading = false;
        this.dialog.closeAll();
      }
    },error=>{this.dialog.closeAll();this.notificationService.showErrorNotification(error,1000);this.loading = false;});
  }
}
