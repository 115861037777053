import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ListingService } from '@app/listing/listing.service';
import { SessionService } from '@app/services/session.service';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss']
})
export class NewHeaderComponent {
  cartlength:any;
  token: any;

  constructor(public listingService:ListingService,private sessionService: SessionService,private router:Router) {
    this.sessionService.authTokenSubject.subscribe((res) => {this.token = res;})
    this.listingService.cartItems.subscribe(res =>{let cartLength:any;cartLength=res.length;this.cartlength = cartLength})
  }

  ngOnInit(): void {
  }
  loginToCheckout(){this.router.navigateByUrl('/');}
}
