import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@app/services/notification.service';
import { fromListingInfoJSON, ListingInfo, ListingInfoApi, ListingInfoForm } from '@models/listing.model';
import { ApiService } from '@services/api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ListingService {
  cache: { listings: ListingInfo[] } = { listings: null };
  cartLocalString = 'QloutCart'
  cartItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  products:any[] = [];
  currentCartTotal:number = 0;
  currentUser: any;
  constructor(private apiService: ApiService,
    private router:Router,
    private notificationService:NotificationService) {
      let r = JSON.parse(localStorage.getItem('user'))
      this.currentUser = r
      this.checkCartandUpdate();
    }

  getAllListings(cleanCache?: boolean): Observable<ListingInfo[]> {
    if (cleanCache) {
      this.cache.listings = null;
    }
    if (this.cache.listings === null) {
      const options: any = {
        params: {
          ts: Date.now(),
        },
      };
      return this.apiService.getAllListings(options).pipe(
        map((response: ListingInfoApi[]) => {
          this.cache.listings = response.map((res) => fromListingInfoJSON(res));
          return this.cache.listings;
        })
      );
    } else {
      return of(this.cache.listings);
    }
  }

  getAllAdminListings(cleanCache?: boolean): Observable<ListingInfo[]> {
    if (cleanCache) {
      this.cache.listings = null;
    }
    if (this.cache.listings === null) {
      const options: any = {
        params: {
          ts: Date.now(),
        },
      };
      return this.apiService.getAllListing().pipe(
        map((response: ListingInfoApi[]) => {
          this.cache.listings = response.map((res) => fromListingInfoJSON(res));
          return this.cache.listings;
        })
      );
    } else {
      return of(this.cache.listings);
    }
  }

  createListing(data: any): Observable<ListingInfo[]> {
    return this.apiService.createListing(data).pipe(concatMap(() => this.getAllListings(false)));
  }

  updateListing(data: any, id: string): Observable<ListingInfo[]> {
    return this.apiService.updateListing(data, id).pipe(concatMap(() => this.getAllListings(false)));
  }

  deleteListing(id: string): Observable<ListingInfo[]> {
    return this.apiService.deleteListing(id).pipe(concatMap(() => this.getAllListings(true)));
  }

  checkCartandUpdate() {
    let items:any = localStorage.getItem(this.cartLocalString);
    items = (items) ? JSON.parse(items) : [];
    this.updateCart(items);
  }

  updateCart(items:any) {
    let r = JSON.parse(localStorage.getItem('user'))
    this.currentUser = r
    localStorage.setItem(this.cartLocalString, JSON.stringify(items));
    this.products = items.filter(res => res.user !== this.currentUser?.id);
    this.cartItems.next(this.products);
    this.cartTotal();
  }

  cartTotal() {
    this.currentCartTotal = 0;
    this.products.forEach(item => {
         this.currentCartTotal += item.price * (item.quantity || 0)
    }
    );
  }

  updateCartItem(item:any, cartItemId:string) {
    let items =  this.cartItems.value;
    let indxItem = items.findIndex(item => item.cartItemId = cartItemId);
    if(indxItem > -1) {
      items[indxItem] = item;
      this.updateCart(items);
      // this._toastr.success('Item has been updated to cart');
      // this.router.navigate(['/cart'])
    } else {
      // this._toastr.error('Oops! something went wrong while updating item');
      return
    }
  }

  addItemToCart(item:any) {
    // if(this.products && this.products?.length > 0) {
    //   this._toastr.error('You already have item in your cart.');
    //   return;
    // }
    let items =  this.cartItems.value;
    items.push(item);
    this.updateCart(items);
    this.notificationService.showSuccessNotification('Listing is add to cart successfully.',2000);
    this.router.navigate(['/cart'])
  }

  removeItemFromCart(item:any) {
    let items =  this.cartItems.value || [];
    let indx = items.findIndex(prd => prd.listingId == item.listingId);
    if(indx > -1) {
      items.splice(indx, 1);
      this.updateCart(items);
      this.notificationService.showSuccessNotification('Item has been removed from cart',2000);
    }
  }

  emptyCart() {
      this.updateCart([]);
  }
}
