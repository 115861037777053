<div class="header-modal">
    <button class="btn" (click)="close()">&times;</button>
<div>

<h1 mat-dialog-title tabindex="-1" cdkFocusInitial align="center">QR Code Scanner</h1>
<div mat-dialog-content>
  <ng-container *ngIf="walletPage == true">
    <div class="setInput">
      <input matInput type="text" name="" id="" placeholder="Search User to pay" class="input-custom1" [(ngModel)]="searchStr" [ngModelOptions]="{ standalone: true }" (keyup)="searchList($event,searchStr)" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let item of filteredUsers" [value]="item?.firsName | async" (qClickKeydown)="openUser(item)">
            {{item?.firstName+'('+item?.uniqueID+')'}}
          </mat-option>
        </mat-autocomplete>
  </div>
  </ng-container>
    <ngx-scanner-qrcode #action="scanner" [config]="{deviceActive: 1}" (event)="onEvent($event, action)"></ngx-scanner-qrcode>

<!--Start-->
<button class="start-btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading" (click)="handle(action, action.isStart ? 'stop' : 'start')">
    <img [src]="action.isStart ? 'https://id1945.github.io/images/svg/camera-off.svg' : 'https://id1945.github.io/images/svg/camera-on.svg'" loading="lazy" width="30px" alt="camera" aria-label="camera"/>
  </button>

  <!-- play/pause -->
  <button class="pause-btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="!action.isStart" (click)="handle(action, action.isPause ? 'play' : 'pause')">
    <img [src]="action.isPause ? 'https://id1945.github.io/images/svg/play.svg': 'https://id1945.github.io/images/svg/pause.svg'" loading="lazy" width="30px" alt="shutter" aria-label="shutter"/>
  </button>

  <!-- isTorch -->
  <button class="flash-btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="!action.isStart" (click)="action.isTorch = !action.isTorch; handle(action, 'torcher')">
    <img [src]="action.isTorch ? 'assets/images/flash-off.svg' : 'assets/images/flash-on.svg'" loading="lazy" width="30px" alt="flash" aria-label="flash"/>
  </button>

<span>
    <a style="display: flex; justify-content: center; font-size: 16px;text-decoration: none;padding-bottom: 20px;padding-top: 50px;" href="{{url ?? 'javascript:void(0)'}}" target="_blank">{{url}}</a>
</span>


<ng-template #sendPopup let-modal>
  <div class="popup">
    <div class="modal-header right-fix">
      <h4 class="modal-title" id="modal-basic-title">Send Amount</h4>
      <button mat-button mat-dialog-close><span>&times;</span></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form"  class="card-row">
      <div class="devide-part">
        <div class="back-page">
          <div class="mdl-cell mdl-cell--12-col">
              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--12-col">
                  <div class="repeat-div">
                    <label class="label-cus" for="">Sender Id</label>
                    <input matInput placeholder="sender" type="text" class="input-custom" formControlName="sender" readonly />
                  </div>
                  <div class="repeat-div">
                    <label class="label-cus" for="">Reciever Id</label>
                    <input matInput placeholder="reciever" type="text" class="input-custom" formControlName="reciever" readonly />
                  </div>
                  <div class="repeat-div">
                    <label class="label-cus" for="">Enter Amount<span class="text-danger">*</span></label>
                    <input matInput placeholder="amount" type="number" min="0" class="input-custom" formControlName="amount" required />
                    <mat-error *ngIf="checkError('amount', 'required') && form.get('amount').touched">This is required</mat-error>
                  </div>
                  <div class="repeat-div">
                    <label class="label-cus" for="">Enter Comment<span class="text-danger">*</span></label>
                    <input matInput placeholder="Comment..." type="text" maxlength="100" class="input-custom" formControlName="comment" required />
                  </div>
                <div class="mdl-cell mdl-cell--12-col publish">
                  <button mat-raised-button color="dark" class="submit_btn" style="margin-left: 10px;" type="submit" (click)="openConfirm()">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  </div>
</ng-template>

<ng-template #confirmatipnPopup let-modal>
  <div class="popup">
    <div class="modal-header right-fix1">
      <h4 class="modal-title" id="modal-basic-title"><strong>Confirmation</strong></h4>
      <button mat-button mat-dialog-close><span>Back</span></button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="showTab">
      <h6 class="card-text font-small-4 mr-25 mb-2">Details of the Transfer:-</h6>
      <p>
        <strong>You (<span class="lstNme_2">{{currentUser.uniqueID}}</span>)</strong> are sending <strong><span class="lstNme_2">{{form?.value?.amount | currency:'CAD' : 'code'}}</span></strong> to <strong><span class="lstNme_2">{{form?.value?.uniqueID}}</span></strong>
    </p>
    <p>
        <strong>Comment: <span class="lstNme_2">{{form?.value?.comment}}</span></strong>
    </p>
      <div class="d-flex justify-content-end">
          <button mat-raised-button color="dark" class="submit_btn mr-1" type="button" (click)="dialog.closeAll()">Cancel</button>
          <button mat-raised-button color="dark" class="submit_btn" type="button" (click)="next()">Next</button>
      </div>
    </ng-container>

    <ng-container *ngIf="!showTab">
      <h6 class="card-text font-small-4 mr-25 mb-2">Are you sure you want to complete the payment?</h6>
      <div class="d-flex justify-content-end my-3">
          <button mat-raised-button color="dark" class="submit_btn mr-1" type="button" (click)="previous()">No</button>
          <button mat-raised-button color="dark" class="submit_btn" type="button" (click)="submitData()" [disabled]="loading"><span *ngIf="loading"><i class="fa fa-spinner"></i></span>Yes</button>
      </div>
    </ng-container>
  </div>
  </div>
</ng-template>