import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '@services/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  role: any;
  allow: boolean;
  constructor(public sessionService: SessionService, public router: Router) {
    let d = JSON.parse(localStorage.getItem('user'));
    this.role = d?.role
    this.allow = window.location.href.includes('view')
  }

  canActivate(): boolean {
    let d = JSON.parse(localStorage.getItem('user'));
    this.role = d?.role
    if (this.role === 'User' ||this.role== 'Admin' || this.allow) {
      return true;
    } else {
      // Redirect to login page or any other page you want for unauthenticated users
      this.router.navigate(['/']);
      return false;
    }
  }

  canLoad(route: Route): boolean {
    let d = JSON.parse(localStorage.getItem('user'));
    this.role = d?.role
    if (this.role === 'User' ||this.role== 'Admin' || this.allow) {
      return true;
    } else {
      // Redirect to login page or any other page you want for unauthenticated users
      this.router.navigate(['/']);
      return false;
    }
  }

  // private checkForAuth(url: string) {
  //    // Replace this with your actual logic to get the user's role.
  //    const userRole = this.role;
  //   // Check if the user has the required role to access the route.
  //   if (userRole == 'User' ||this.role== 'Admin' || this.allow) {
  //     const token = this.sessionService.authTokenValue;
  //     if (!token) {
  //       // not logged in so redirect to login page with the return url
  //       this.router.navigate(['/sign-in'], { queryParams: { returnUrl: url } });
  //       return false;
  //     }
  //   }
  //   return true;
  // }
}
