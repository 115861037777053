import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { copyTextAreaToClipBoardLink } from '@app/_helper/util';
import { ApiService } from '@app/services/api.service';
import { NotificationService } from '@app/services/notification.service';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';

@Component({
  selector: 'app-load-wallet',
  templateUrl: './load-wallet.component.html',
  styleUrls: ['./load-wallet.component.scss'],
})
export class LoadWalletComponent {
  @ViewChild('tabGroup', { static: true }) tabGroup!: MatTabGroup;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  currentUser: any; eTransfer_show: boolean = true; billing_show: boolean = false; secret: any;
  active: boolean = false; card_show: boolean = false; stripe: any; amount: any;errorMessage:any=''
  elementsOptions: StripeElementsOptions = { locale: 'en' };
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#000',
        color: '#000',
        fontWeight: '300',
        fontFamily: '"Montserrat',
        fontSize: '14px',
      }
    }
  };
  selectedIndex: number = 0; error: boolean; limit: any;
  loading: boolean = false;
  errorMessage1: string;
  errorMessage2: string;

  constructor(private location: Location, private apiService: ApiService, private router: Router, private stripeService: StripeService, private notificationService: NotificationService) {
    let r = JSON.parse(localStorage.getItem('user')); this.currentUser = r
    this.getSecret(); this.getLimit();
  }

  changeTab(type) {
    this.nextStep()
    this.tabGroup.selectedIndex = 1
    if (type == 'e-Transfer') { this.eTransfer_show = true; this.billing_show = false; this.card_show = false; }
    else if (type == 'Bill Payee') { this.eTransfer_show = false; this.billing_show = true; this.card_show = false; }
    else if (type == 'Card') { this.card_show = true; this.eTransfer_show = false; this.billing_show = false; }
  }

  back() { this.location.back() }

  cancel() { this.eTransfer_show = false; this.billing_show = false; this.card_show = false; }

  copyText(data) { let path = data; copyTextAreaToClipBoardLink(path); this.notificationService.showSuccessNotification('Copied Successfully!', 1000) }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void { this.selectedIndex = tabChangeEvent.index; }

  public nextStep() { this.selectedIndex += 1; }

  public previousStep() { this.selectedIndex -= 1; }

  getSecret() { this.apiService.getSecret().subscribe((res: any) => { this.secret = res[0] }) }

  getLimit() { this.apiService.getLimit().subscribe((res: any) => { this.limit = res[0]; }) }

  submitted() {
    if (this.amount < Number(this.limit.loadLimit)) {
      this.error = true;
      this.errorMessage = `Minimum  CAD${this.limit?.loadLimit} is required`;
      return;
    } else if (!this.amount) {
      this.errorMessage1 = `this field is required`
      return;
    } else if (!this.card.element) {
      this.errorMessage2 = 'Card details is required';
      return;
    }else {
      this.stripeService.createToken(this.card.element).subscribe((result) => {
        if (result.token) {
          this.loading = true;
          let data = { amount: this.amount, userId: this.currentUser.id, token: result.token }
          this.apiService.addWallet(data).subscribe((res) => {
            if (!res.error) {
              this.loading = false;
              this.error = false; this.notificationService.showSuccessNotification(res.msg, 1000); this.router.navigate(['/wallet'])
              this.errorMessage ='',
              this.errorMessage2=''
            }
            else { this.notificationService.showErrorNotification(res.msg, 1000) }
          }, error => {
            this.error = false;
            this.loading = false;
          })
        }
      }, error => { this.error = false;this.loading = false;this.notificationService.showErrorNotification(error, 1000) });
    }
  }
  change(event: any) {
    if (!event.error) {
      this.loading = false;
      this.errorMessage2 =''
    } else {
      // this.loading = true
      this.errorMessage2 = 'Card details is required';
  
    }
  }
}
