import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@app/services/api.service';
import { NotificationService } from '@app/services/notification.service';

@Component({
  selector: 'app-cancel-order-popup',
  templateUrl: './cancel-order-popup.component.html',
  styleUrls: ['./cancel-order-popup.component.scss']
})
export class CancelOrderPopupComponent {
  order_id: any;
  loading: boolean;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,private api: ApiService,private notificationService: NotificationService) {this.order_id = data}

  cancelOrderRequest() {
    this.loading = true;
    let body = {orderId:this.order_id}
    this.api.cancelOrderRequest(body).subscribe(res=>{
      if(!res.error) {
        this.loading = false;
        if(res.msg=="you already created a cancel request for this order") {this.notificationService.showErrorNotification(res.msg,1000)}
        } else {this.notificationService.showErrorNotification(res.msg,1000)}
      this.close();
    },error=>{this.notificationService.showErrorNotification(error,1000);this.loading = false;});
  }

  close() {this.dialogRef.close()}

}
