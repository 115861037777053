<div fxLayout="row" fxLayoutAlign="center" class="container" ngClass.gt-md="container-desktop">
  <img class="background-img" src="/assets/logo_bg_210.svg" alt="logo" loading="lazy">
  <div fxFlex.gt-md="40%" fxLayoutAlign="center center">
    <mat-card fxLayout="column" fxLayoutAlign="center">
      <img src="/assets/logo.svg" loading="lazy" alt="logo" aria-label="logo"/>
      <h1>Enter your email and we will send you a password reset link</h1>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgetSubmit()" fxLayout="column" fxLayoutAlign="center">
        <mat-form-field appearance="fill">
          <mat-label>Enter Email Address</mat-label>
          <input matInput placeholder="Email" formControlName="email" type="email" autocomplete="username" required />
          <mat-icon matSuffix>email</mat-icon>
          <mat-error *ngIf="email.invalid">{{ getEmailErrorMessage() }}</mat-error>
        </mat-form-field>
        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-around center" fxLayoutGap="20px" class="button-group">
          <button mat-flat-button color="primary" routerLink="/">Cancel</button>
          <button mat-flat-button color="warn" type="submit" [disabled]="!forgotPasswordForm.valid">Confirm</button>
        </div>
      </form>
    </mat-card>
  </div>
  <div fxHide fxShow.gt-md fxFlex="60%" class="image-container"></div>
</div>
