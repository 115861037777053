import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  icon: string;
  role:any [];
  enable: boolean;
  permission: string;
}

const MENUITEMS: Menu[] = [
  { state: 'dashboard',permission: 'dashboard', name: 'Dashboard', icon: 'av_timer',role:['User','Admin'],enable:false},
  { state: 'eshop',permission: 'eshop', name: 'E-shop', icon: 'store', role:['User'],enable:false},
  { state: 'admin/eshop',permission: 'adminEshop', name: 'E-shop', icon: 'store', role:['Admin'],enable:false},
  { state: 'qtag',permission: 'qtag', name: 'Qtag', icon: 'list',role:['User'],enable:false},
  { state: 'admin/qtag',permission: 'adminQtag', name: 'Qtag', icon: 'list', role:['Admin'],enable:false},
  { state: 'admin/users',permission: 'users', name: 'Users', icon: 'people', role: ['Admin'],enable:false} ,
  { state: 'profile',permission: 'userProfile', name: 'User Profile', icon: 'person',role:['User'],enable:false},
  { state: 'admin/profile',permission: 'adminProfile', name: 'Admin Profile', icon: 'person',role:['Admin'],enable:false},
  { state: 'notification',permission: 'notification', name: 'Notifications', icon: 'notifications', role:['User','Admin'],enable: false},
  { state: 'admin/calendarView',permission: 'calanderView', name: 'Calendar View', icon: 'calendar_today', role:['Admin'],enable:false},
  { state: 'admin/reviews',permission: 'reviews', name: 'Reviews', icon: 'reviews', role: ['Admin'],enable:false},
  { state: 'admin/roles',permission: 'roles', name: 'Menu', icon: 'menu', role: ['Admin'],enable:false},
  { state: 'admin/walletSettings',permission: 'walletSettings', name: 'Wallet Settings', icon: 'account_balance_wallet', role: ['Admin'],enable:false},
  { state: 'admin/withdraw',permission: 'Withdrawal', name: 'Withdrawal', icon: 'payment',role:['Admin'],enable:false},
  { state: 'wallet',permission: 'wallet', name: 'Wallet', icon: 'account_balance_wallet', role: ['User'],enable:false},
  { state: 'admin/dispute',permission: 'dispute', name: 'Dispute', icon: 'warning', role: ['Admin'],enable: false},
  { state: 'network',permission: 'myNetwork', name: 'My Network', icon: 'question_answer',role:['User'],enable:false},
  { state: 'analytics',permission: 'analytics', name: 'Analytics', icon: 'bar_chart',role:['User','Admin'],enable:false},
  // { state: 'map-view',permission: 'mapView', name: 'Map View', icon: 'map',role:['User','Admin'],enable:false},
  { state: 'admin/report',permission: 'report', name: 'Reports', icon: 'report',role:['Admin'],enable:false},
  { state: 'admin/orders',permission: 'Orders', name: 'Orders', icon: 'shop_two',role:['Admin'],enable:false},
  { state: 'search',permission: 'search', name: 'Search', icon: 'search',role:['User','Admin'],enable:false},
  { state: 'chat',permission: 'chat', name: 'Chat', icon: 'chat',role:['User'],enable:false},
  { state: 'my-orders',permission: 'myOrders', name: 'My Orders', icon: 'shop_two',role:['User'],enable:false},
  { state: 'incoming-orders',permission: 'incomingOrders', name: 'Incoming Orders', icon: 'shopping_basket',role:['User'],enable:false},
  // { state: 'invoice',permission: 'invoices', name: 'Invoices',icon: 'assignment', role: ['User'],enable:false},
  { state: 'setting',permission: 'settings', name: 'Settings', icon: 'settings',role:['User','Admin'],enable:false},
];

@Injectable({
  providedIn: 'root',
})
export class MenuItemsService {
  role: any;
  constructor() {
  }

  getMenuitems(): Menu[] {
    let d = JSON.parse(localStorage.getItem('user'));
    this.role = d?.role
    return MENUITEMS.filter(item => !item.role?.length || item.role.includes(this.role));
  }
}
