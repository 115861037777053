<div fxLayout="row" fxLayoutAlign="center" class="container" ngClass.gt-md="container-desktop">
    <img class="background-img" src="/assets/logo_bg_210.svg" alt="" srcset="">
    <div fxFlex.gt-md="40%" fxLayoutAlign="center center">
        <mat-card fxLayout="column" fxLayoutAlign="center">
            <h1>Sign Up</h1>
            <img src="/assets/logo.svg" alt="logo" aria-label="logo" loading="lazy"/>
            <p class="header-text">Create your lifetime <strong>FREE</strong> account here</p>
            <form [formGroup]="signupForm" (ngSubmit)="registerUser()" fxLayout="column" fxLayoutAlign="center">
                <mat-form-field appearance="fill">
                    <mat-label>Enter First Name</mat-label>
                    <input matInput placeholder="First Name" formControlName="firstName" type="text" autocomplete="firstName" required />
                    <mat-icon matSuffix>person</mat-icon>
                    <mat-error *ngIf="firstName.invalid">{{ getFirstnameErrorMessage() }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Enter Last Name</mat-label>
                    <input matInput placeholder="Last Name" formControlName="lastName" type="text" autocomplete="lastName" required />
                    <mat-icon matSuffix>person</mat-icon>
                    <mat-error *ngIf="email.invalid">{{ getLastnameErrorMessage() }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Enter Email Address</mat-label>
                    <input matInput placeholder="Email" formControlName="email" type="email" autocomplete="username" required />
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="email.invalid">{{ getEmailErrorMessage() }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="current-password" required />
                    <button mat-icon-button matSuffix (qClickKeydown)="hide = !hide" [attr.aria-label]="hide ? 'Show password' : 'Hide Password'">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
                    <mat-error *ngIf="password.invalid">{{ getPasswordErrorMessage() }}</mat-error>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="start center" class="tnc-container">
                    <mat-checkbox color="primary" formControlName="acceptTerms" required></mat-checkbox>
                    <p class="one-line-all">
                        I agree with <a mat-button color="warn" (qClickKeydown)="openTermsAndConditions()">Terms &amp; Conditions</a> &
                        <a mat-button color="warn" (qClickKeydown)="openPrivacyPolicy()">Privacy policy</a>
                    </p>

                </div>
                <button mat-flat-button color="warn" type="submit" [disabled]="!signupForm.valid" class="bold-text"><span><i class="fa fa-spinner" *ngIf="loading"></i></span>Sign Up</button>
            </form>
            <div fxLayout="row" fxLayoutAlign="center center">
                <p>Already have an account? <a mat-button color="warn" routerLink="/" class="bold-text">Log In</a>
                </p>

            </div>
        </mat-card>
    </div>
    <div fxHide fxShow.gt-md fxFlex="60%" class="image-container"></div>
</div>