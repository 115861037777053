import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/services/api.service';
import { UserInfo } from '@app/models/profile.model';
import { ShareOptionsComponent } from '@app/share-options/share-options.component';
import { MatDialog } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { NotificationService } from '@app/services/notification.service';
import { Location } from '@angular/common';
import { SessionService } from '@app/services/session.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent {
  @ViewChild('contentToConvert') contentToConvert!: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('activatelistPopup', { static: false }) activatelistPopup: any;
  @ViewChild('activateEshopPopup', { static: false }) activateEshopPopup: any;
  
  list: any[]=[];publicList: any[]=[];user:any;id:any;currentUrl: any;follow: boolean = false;uniqueID: any;
  ProfileUrl:string='https://api.qrserver.com/v1/create-qr-code/?size=80x80&data=https://myqlout.com/profile/Profile-view/'
  currentUser:any;loading:boolean = false;download:any;qrCode: any;downloadQr: any;isFollowingUser:boolean;userItself:boolean = false;age: number;

  constructor(private _activatedRoute:ActivatedRoute,private api:ApiService,private renderer: Renderer2,private locton: Location,private el: ElementRef,public dialog: MatDialog,private notificationService: NotificationService, private router:Router,public session: SessionService){
    this._activatedRoute.params.subscribe(res=>{
       let user = JSON.parse(localStorage.getItem('user'))
       this.currentUser = user || '';
      this.uniqueID = atob(res.uniqueID ?? user.uniqueID);
      this.qrCode = this.ProfileUrl + btoa(this.uniqueID)
    })
    this.currentUrl = location.href;
  }

  slideConfig = {
    slidesToShow: 1,slidesToScroll: 1,infinite: true,autoplay: true,vertical:false,arrows:true,speed:500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {slidesToShow: 1,slidesToScroll: 1,centerMode: false,arrows:true},
      },
      {
        breakpoint: 991,
        settings: {slidesToShow: 1,slidesToScroll: 1,centerMode: false,arrows:true},
      },
      {
        breakpoint: 767,
        settings: {slidesToShow: 1,slidesToScroll: 1,centerMode: false,arrows:true},
      },
      {
        breakpoint: 480,
        settings: {slidesToShow: 1,slidesToScroll: 1,centerMode: false,arrows:true},
      },
    ],
  };
  
  slickInit(e) {}  breakpoint(e) {}  afterChange(e) {}  beforeChange(e) {}

  ngOnInit() {this.getData()}
  
  back() {this.locton.back()}

  openActive() {
    this.dialog.open(this.activatelistPopup, {
      width: '400px',
      height: '250px',
      disableClose: true
    })
  }
  openActiveEshop() {
    this.dialog.open(this.activateEshopPopup, {
      width: '400px',
      height: '250px',
      disableClose: true
    })
  }
  goback() {this.dialog.closeAll()}

  openShareOptions(data) {
    data.shareUrl = location.origin+`/profile/Profile-view/`+btoa(`${data.uniqueID}`);
    data.type = 'profileView';
    this.dialog.open(ShareOptionsComponent, {
      width: '500px',
      height:'150px',
      data: data,
      disableClose: true
    });
  }
  
  getData() {this.loading = true;
    this.api.getByUniqueId(this.uniqueID).subscribe((res:any)=>{
      this.user = res.account;
      if(this.user?.isBirthday == true){
        const convertAge = new Date(this.user?.birthDate);
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        this.age = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
      }
      this.loading = false
      this.publicList = res.listing.filter(i => i.listing.isDelete == 'false' && i.listing.block == 'no' && (i.listing.published == true || i.listing.visible == 'yes') && i.listing.isApproval == 'approved');
      this.list = res.estore.filter(i => i.eStore.block == "no" && i.eStore.publish != "draft");
      this.downloadQr = this.ProfileUrl + btoa(this.user.uniqueID)
      if(this.user.id != this.currentUser.id) {this.getFollowers();}
      if(this.currentUser?.id != this.user?.id) {this.api.getDeviceInfoSaved('user', this.user.id, this.currentUser);}
      else if(this.currentUser?.id === this.user?.id){this.userItself = true;}
    })
  }

  getFollowers(){
    if(this.currentUser){
      this.api.followingUsers().subscribe((res)=>{
        const followerList = res;
        const isFollowing = followerList.filter((follower)=>{return follower.uniqueID === this.user?.uniqueID})
        if(isFollowing.length >0){this.isFollowingUser = true;}
        else{this.isFollowingUser = false;}   
      })
    }
  }  

  followButton(id) {
    if(this.currentUser){
      let body = {user: id._id ?? id.id}
      this.api.followUser(body).subscribe(res => {this.getFollowers();})
    }
    else{
      this.notificationService.showErrorNotification('Please login first',1000)
      setTimeout(()=>{this.router.navigate(['login'])},1000)
    }
  }

  unfollowButton(id) {
      let body = {user: id._id ?? id.id }
      this.api.unfollowUser(body).subscribe(res => {this.getFollowers();})
  }

  followListing(data?) {
      let body = {listing: data.listing.id}
      this.api.followListing(body).subscribe(res => {})
      this.getData()
  }

  followEstore(data?) {
    let body = {eStore: data.eStore.id}
    this.api.followEstore(body).subscribe(res => {
    if(res.followingUserId)this.follow = true;})
    this.getData()
  }

  unfollowListing(data?) {
      let body = {listing: data.listing._id}
      this.api.unfollowListing(body).subscribe(res => {})
  }

  unfollowEstore(data?) {
    let body = {eStore: data.eStore._id}
    this.api.unfollowEstore(body).subscribe(res => {})
  }

  estoreRoute(data?) {if(data.eStore.block == 'yes') {this.openActiveEshop();return;} this.router.navigate([`/eshop/view/`+btoa(`${data?.eStore?.id}`)])}

  listRoute(data?) {if(data.block == 'yes') {this.openActive();return;} this.router.navigate([`/qtag/view/`+btoa(`${data?.listing?.id ?? data?.listing?._id }`)])}

  navigateChat() {this.router.navigate([`/chat/chat_id/`+btoa(`${this.user?.id}`)+'/'+ btoa(`${'user'}`)])}

  async downloadCode(e: UserInfo) {
    this.download = e;
    this.loading = true;
    await new Promise(resolve => setTimeout(resolve, 1000));
    this.convertAndDownload()
  }

  async convertAndDownload(): Promise<void> {
    try {
        const content: any = this.el.nativeElement.querySelector('#contentToConvert');
        /* Wait for the content to be fully rendered*/ await new Promise((resolve) => setTimeout(resolve, 2000));
        /* Capture the content as a canvas using html2canvas*/const canvas = await html2canvas(content, { useCORS: true, allowTaint: true, scale: window.devicePixelRatio, logging: false });
        /* Convert canvas to data URL with compression*/ const imageData = canvas.toDataURL('image/jpeg', 0.8);
        /* Create a download link*/const downloadLink = document.createElement('a');
        downloadLink.href = imageData;
        downloadLink.download = `${this.download?.firstName}_${this.download?.lastName} (${this.download?.uniqueID}).jpg`;
        /* Trigger download immediately*/downloadLink.click();
        /* Clean up after download*/ this.loading = false;
    } catch (error) {
        console.error('Error during conversion and download:', error);
        this.loading = false;
    }
}

}
