// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  socketUrl:'chat.myqlout.com',
  // stripe_pk:'pk_test_51HcjDfIDD2jQaejEtqO4uSXeOgOSNZp4v4u5HmeOZzO2D2bg7wAzkv6xcFVAOSxCcnT2ApoIWUUK16yMEBxqYSDl00HwAcsrse',  //Our
  stripe_pk:'pk_live_51KE6XlJM0H8QRPJD99OC4tkUQ4WDnqb12ZFlYsvyjwqW9aeA0vcwJv9tIl7g3V0wSslql1OzWjel7LQNSV6RcKnC00XKamJfBO', //prod client
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
