export const BASE = {
  USERS: 'https://api.myqlout.com/api/users',
  LISTING: 'https://api.myqlout.com/api/listing',
  ESTORE: 'https://api.myqlout.com/api/estore',
};

export const EndpointConstants = {
  USER: BASE.USERS,
  LISTING: BASE.LISTING,
  ESTORE: BASE.ESTORE,
  FORGOT_PASSOWRD: BASE.USERS + '/forgot-password',
  RESET_PASSOWRD: BASE.USERS + '/reset-password',
  REGISTER: BASE.USERS + '/register',
  LOGIN: BASE.USERS + '/login',
  CREATE_LISTING: BASE.LISTING + '/create',
  CREATE_ESTORE: BASE.ESTORE + '/create',
  BaseUrl: 'https://api.myqlout.com/api/',

};
