<h1 mat-dialog-title>Select Location on Map</h1>
<div mat-dialog-content class="map-container">
    <google-map class="map" [zoom]="zoom" [center]="center" [options]="options" (mapClick)="mapClicked($event)" [width]="mapWidth" height="400px">
        <map-marker [position]="coords" *ngIf="isLocationChoosen"></map-marker>
    </google-map>
</div>
<div mat-dialog-actions class="action-btns">
    <button mat-button class="location-btn" (click)="getMyLocation()">My Current Location</button>
    <div class="close-btns">
        <button mat-button mat-dialog-close class="cancel-btn">Cancel</button>
        <button mat-button class="select-btn" [mat-dialog-close]="locationData">Select</button>
    </div>
</div>