import { Injectable } from '@angular/core';
import { copyTextAreaToClipBoard, copyTextAreaToClipBoardLink } from '../_helper/util'
@Injectable({
  providedIn: 'root'
})
export class NetworkServiceService {

  constructor() { }

  copyInfoUrle(data){
    let path =  data;
    copyTextAreaToClipBoard(path);
    }

    copyEshopLink(data) {
      let path = window.location.href+'/view/'+data;
      copyTextAreaToClipBoardLink(path);
    }

    copyListingLink(data) {
      let path = window.location.href+'/view/'+data;
      copyTextAreaToClipBoardLink(path);
    }
}
