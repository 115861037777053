<div fxLayout="column" fxLayoutAlign="center" class="container-fluid" style="width: 100%;max-width: 100%!important;">
    <h1 class="all-view-head" style="font-size: 24px;">Invoices</h1>
    <mat-card fxLayout="column">
        <div class="over-flow">
            <table style="width: 100%;" mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="month">
                    <th mat-header-cell *matHeaderCellDef>INVOICE MONTHS</th>
                    <td mat-cell  *matCellDef="let element">{{ element?.monthName }} Invoice</td>
                </ng-container>
                <ng-container matColumnDef="download">
                    <th mat-header-cell *matHeaderCellDef>DOWNLOAD</th>
                    <td mat-cell *matCellDef="let element">
                        <a [href]="'javascript:void(0)'" (qClickKeydown)="downloadPdf(element)"><img src="assets/images/pdf-down.jpeg" alt="pdf" aria-label="pdf" loading="lazy" style="width: 60px;padding:10px" /></a>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <ng-container *ngIf="!invoiceData?.length">
                <div class="text-center">No Data Available</div>
            </ng-container>
        </div>
    </mat-card>

</div>