import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-confirmation',
  templateUrl: './login-confirmation.component.html',
  styleUrls: ['./login-confirmation.component.scss']
})
export class LoginConfirmationComponent {

  constructor(private router:Router) {}
  
  loginToCheckout(){this.router.navigateByUrl(`/login?checkout=${true}`);}
}
