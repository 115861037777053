import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingQrCodeComponent } from '../listing-qr-code/listing-qr-code/listing-qr-code.component';
import { ListingQrCodeRoutingModule } from './listing-qr-code-routing.module';
import { MaterialModule } from '@app/material/material.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ClickKeydownModule } from '@app/utils/click-keydown/click-keydown.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [ListingQrCodeComponent],
  imports: [
    CommonModule,
    ListingQrCodeRoutingModule,
    MaterialModule, SlickCarouselModule, ClickKeydownModule, FlexLayoutModule, ReactiveFormsModule
  ],
  exports: [ListingQrCodeComponent]
})
export class ListingQrCodeModule { }
