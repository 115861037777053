import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '@app/services/api.service';
import { NotificationService } from '@app/services/notification.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent {
  displayedColumns: string[] = ['month','download'];
  dataSource: any;
  invoiceData:any[]=[];
  currentUser: any;

  constructor(private api:ApiService,private notification:NotificationService){let r = JSON.parse(localStorage.getItem('user'));this.currentUser = r}
  
  uuidv4() {return "10000000-1000-4000-8000-100000000000".replace(/[018]/g,(c:any) =>(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));}

  ngOnInit(): void {this.dataSource = new MatTableDataSource<any>();this.getVenderPaymentData()}

  getVenderPaymentData(data?) {
    this.api.getVenderPaymentData().subscribe((res:any)=>{
        this.invoiceData = res.order.map((i:any)=>{i.monthNo = i.month.split('-')[1];i.monthName = i.monthNo == '1' ? 'January' : i.monthNo == '2' ? 'February' : i.monthNo == '3' ? 'March' : i.monthNo == '4' ? 'April' : i.monthNo == '5' ? 'May' : i.monthNo == '6' ? 'June' : i.monthNo == '7' ? 'July' : i.monthNo == '8' ? 'August' : i.monthNo == '9' ? 'September' : i.monthNo == '10' ? 'October' : i.monthNo == '11' ? 'November' : 'December';i.year = i.month.split('-')[0];return i;});
        this.dataSource = new MatTableDataSource<any>(this.invoiceData);
    },error=>{this.notification.showErrorNotification(error)})
  }

  downloadPdf(data) {
    fetch(data.pdfLink)
    .then(response => response.arrayBuffer())
    .then(pdfData => {
      const a = document.createElement('a');const blob = new Blob([pdfData], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;a.download = data.monthName+' invoice.pdf';a.click();
      window.URL.revokeObjectURL(url);a.remove();
    }).catch(error => {console.error('Failed to fetch PDF data:', error);});
  }
}
