import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointConstants } from '@constants/endpoint-constants';
import { EShopInfo } from '@models/eshop.model';
import { ListingInfoApi, ListingInfoForm } from '@models/listing.model';
import { UserInfo, UserInfoApi } from '@models/profile.model';
import { UserLogin, UserSignup } from '@models/signin.model';
import { BlobDownload, ImageDownload } from '@models/util.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  ipApiArray: any[] = [
    '17c89847-7183-49ea-a58f-28ea04751413','64b1272e-5768-471c-9ab7-f54c28ff80fc',  'd60ac0c9-f818-465a-ba60-32dc4f1695b3', 'ba2aac8d-d84a-4e31-ac69-e88890a02e93',
    '84695e48-c0f6-43ae-af6e-ca6cd21580f3', 'a9bc6f2c-d783-48a3-9753-6d096e8d39a1',
]; 
  expiredIpApis:any[] =[];
  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  public notifications: any[]=[];
  public chatsBubble: any[]=[];
  constructor(private http: HttpClient,private deviceService:DeviceDetectorService) {}

  /* UNAUTHENTICATED */
  public data = new Subject()  
  public dataSubject = new BehaviorSubject<boolean>(false);
  logIn(user: UserLogin): Observable<any> {
    return this.http.post(EndpointConstants.LOGIN, user);
  }

  forgotPwd(email): Observable<any> {
    return this.http.post(EndpointConstants.FORGOT_PASSOWRD, email);
  }

  resetPwd(reset): Observable<any> {
    return this.http.post(EndpointConstants.RESET_PASSOWRD, reset);
  }

  signUp(user: UserSignup): Observable<any> {
    return this.http.post(EndpointConstants.REGISTER, user);
  }

  /* QR Code */

  getQrCodeBlobs(imgInfoArray: ImageDownload[]): Observable<BlobDownload>[] {
    return imgInfoArray.map((imgInfo) => {
      return this.http.get(imgInfo.url, { responseType: 'blob' }).pipe(
        map((blob) => {
          return { blob, name: imgInfo.name };
        }),
        catchError((e) => of(null))
      );
    });
  }

  /* USER PROFILE */

  getUserProfile(id, options = {}): Observable<UserInfoApi> {
    const api = `${EndpointConstants.USER}/${id}`;
    return this.http.get<UserInfoApi>(api, { headers: this.headers, ...options });
  }

  completeProfile(user: UserInfo, id): Observable<any> {
    const api = `${EndpointConstants.USER}/${id}`;
    return this.http.put(api, user);
  }

  followUser(id:any):Observable<any>{
    const api = `${EndpointConstants.USER}/follow`;
    return this.http.post(api,id);

  }
  userFollowers():Observable<any>{
    const api = `${EndpointConstants.USER}/followers/users`;
    return this.http.get(api);
  }

  followingEstore():Observable<any>{
    const api = `${EndpointConstants.USER}/following/estores`;
    return this.http.get(api);
  }

  followingListing():Observable<any>{
    const api = `${EndpointConstants.USER}/following/listings`;
    return this.http.get(api);
  }

  followingUsers():Observable<any>{
    const api = `${EndpointConstants.USER}/following/users`;
    return this.http.get(api);
  }
  unfollowUser(id:any):Observable<any>{
    const api = `${EndpointConstants.USER}/unfollow`;
    return this.http.post(api,id);
  }
  getFollowerListingUsers():Observable<any> {
    const api = `${EndpointConstants.USER}/followers/listingUsers`;
    return this.http.get(api);
  }

  getFollowerEstoreUsers():Observable<any> {
    const api = `${EndpointConstants.USER}/followers/estoreUsers`;
    return this.http.get(api);
  }

  getFollowerUsers():Observable<any> {
    const api = `${EndpointConstants.USER}/followers/users`;
    return this.http.get(api);
  }

  getDataById(id:any):Observable<any> {
    const api = `${EndpointConstants.USER}/public/${id}`;
    // const api = `${EndpointConstants.USER}/dataById/${id}`;
    return this.http.get(api);
  }

  getByUniqueId(id:any):Observable<any> {
    const api = `${EndpointConstants.USER}/getByUniqueId/${id}`;
    // const api = `${EndpointConstants.USER}/dataById/${id}`;
    return this.http.get(api);
  }

  toggleUpdate(id,body):Observable<any> {
    const api = `${EndpointConstants.USER}/toggleUpdate/${id}`;
    return this.http.put(api,body);
  }
  SaveLocation(body):Observable<any> {
    let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders.append('noAtuh', 'true');
    const api = `${EndpointConstants.USER}/location`;
    return this.http.post(api,body,{headers:httpHeaders});
  }
  verifyEmail(data:any):Observable<any> {
    const api = `${EndpointConstants.USER}/verify-email`;
    return this.http.post(api,data);
  }
  resendVerifyEmail(data:any):Observable<any> {
    const api = `${EndpointConstants.USER}/resendEmail`;
    return this.http.post(api,data);
  }
  getLocation(body):Observable<any> {
    const api = `${EndpointConstants.USER}/location/${body}`;
    return this.http.get(api);
  }
  getAnalyticsData(id):Observable<any> {
    const api = `${EndpointConstants.USER}/getAnalyticsData/${id}`;
    return this.http.post(api,{});
  }
  getWeeklyAnalyticsData(body):Observable<any> {
    const api = `${EndpointConstants.USER}/getGraphData`;
    return this.http.post(api,body);
  }

  getAllUsers():Observable<any> {
    const api = `${EndpointConstants.USER}`;
    return this.http.get(api)
  }

  blockUser(body:any) {
    const api = `${EndpointConstants.USER}/blockUser`;
    return this.http.post(api,body);
  }

  getWalletData(body:any):Observable<any> {
    return this.http.post(`${EndpointConstants.USER}/getWalletData`,body); 
  }

  addWallet(body:any):Observable<any> {
    return this.http.post(`${EndpointConstants.USER}/addWallet`,body); 
  }

  cancelOrderRequest(body:any):Observable<any> {
    return this.http.post(`${EndpointConstants.USER}/cancelOrderRequest`,body); 
  }

  addRolePermission(body:any):Observable<any> {
    return this.http.post(`${EndpointConstants.USER}/addRolePermission`,body); 
  }

  editRolePermission(body:any):Observable<any> {
    return this.http.post(`${EndpointConstants.USER}/editRolePermission`,body); 
  }

  getRolePermission(body):Observable<any> {
    const api = `${EndpointConstants.USER}/getRolePermission/${body}`;
    return this.http.get(api);
  }

  addSecretKey(body:any):Observable<any> {
    return this.http.post(`${EndpointConstants.USER}/addSecret`,body); 
  }

  addWithdrawalLimit(body:any): Observable<any> {
    return this.http.post(`${EndpointConstants.USER}/addWithdrawalLimit`,body)
  }

  addDistributePercentage(body:any): Observable<any> {
    return this.http.post(`${EndpointConstants.USER}/addDistributePercentage`,body)
  }

  getSecret():Observable<any> {
    const api = `${EndpointConstants.USER}/getSecret`;
    return this.http.post(api,'');
  }

  getLimit():Observable<any> {
    const api = `${EndpointConstants.USER}/getLimit`;
    return this.http.post(api,'')
  }

  getDistributePercentage():Observable<any> {
    const api = `${EndpointConstants.USER}/getDistributePercentage`;
    return this.http.post(api,'')
  }

  getReport():Observable<any> {
    const api = `${EndpointConstants.USER}/getReport`;
    return this.http.post(api,'')
  }

  ptpTransfer(body:any): Observable<any> {
    const api = `${EndpointConstants.USER}/ptpTransfer`;
    return this.http.post(api,body)
  }

  createDispute(body:any): Observable<any> {
    const api = `${EndpointConstants.USER}/createDispute`;
    return this.http.post(api,body)
  }

  getDispute(): Observable<any> {
    const api = `${EndpointConstants.USER}/getDispute`;
    return this.http.post(api,'')
  }
  
  closeDispute(body:any): Observable<any> {
    const api = `${EndpointConstants.USER}/closeDispute`;
    return this.http.post(api,body)
  }

  walletStatus(body:any): Observable<any> {
    const api = `${EndpointConstants.USER}/walletStatus`;
    return this.http.post(api,body)
  }
  messaging(): Observable<any> {
    const api = `${EndpointConstants.BaseUrl}/messaging`;
    return this.http.get(api)
  }
  withdrawalUserMoney(body:any){
const api=`${EndpointConstants.USER}/withdrawalUserMoney`;
return this.http.post(api,body)
  }

  distributeCred(body:any): Observable<any> {
    const api = `${EndpointConstants.BaseUrl}/payment/distributePayment`;
    return this.http.post(api,body)
  }

  getWithdrawalRequests(): Observable<any> {
    const api = `${EndpointConstants.USER}/getWithdrawalRequests`;
    return this.http.post(api,'')
  }

  approveWithdrawalRequests(body:any): Observable<any> {
    const api = `${EndpointConstants.USER}/approveWithdrawalRequest`;
    return this.http.post(api,body)
  }

  rejectWithdrawalRequests(body:any): Observable<any> {
    const api = `${EndpointConstants.USER}/rejectWithdrawalRequest`;
    return this.http.post(api,body)
  }

  changePassword(body:any): Observable<any> {
    const api = `${EndpointConstants.USER}/changePassword`;
    return this.http.post(api,body)
  }

  contactUs(body:any): Observable<any> {
    const api = `${EndpointConstants.USER}/contactUs`;
    return this.http.post(api,body)
  }








  /* LISTING */

  getAllListings(options = {}): Observable<ListingInfoApi[]> {
    return this.http.get<ListingInfoApi[]>(EndpointConstants.LISTING, { headers: this.headers, ...options });
  }

  updateListing(listing: ListingInfoForm, id: string): Observable<any> {
    const api = `${EndpointConstants.LISTING}/${id}`;
    return this.http.put(api, listing);
  }

  createListing(listing: ListingInfoForm): Observable<any> {
    return this.http.post(EndpointConstants.CREATE_LISTING, listing);
  }

  deleteListing(id: string): Observable<any> {
    const api = `${EndpointConstants.LISTING}/${id}`;
    return this.http.delete(api);
  }
  SearchMyListing(text:string,value:any): Observable<any> {
    const api = `${EndpointConstants.LISTING}?text=${text}`;
    return this.http.post(api,{});
  }
  followListing(id:any):Observable<any>{
    const api = `${EndpointConstants.LISTING}/follow`;
    return this.http.post(api,id);
  }
  publicListing(id:any):Observable<any>{
    const api = `${EndpointConstants.LISTING}/public/${id}`;
    return this.http.get(api);
  }
  unfollowListing(id:any):Observable<any>{
    const api = `${EndpointConstants.LISTING}/unfollow`;
    return this.http.post(api,id);
  }
  reportList(body:any){
    const api = `${EndpointConstants.LISTING}/addReport`;
    return this.http.post(api,body);
  }
  
  getAllListing():Observable<any> {
    const api = `${EndpointConstants.LISTING}/public`;
    return this.http.post(api,'');
  }
  updateListingBlockStatus(id:any) {
    const api = `${EndpointConstants.LISTING}/updateBlockStatus`;
    return this.http.post(api,id);
  }

  listingApproval(data:any) {
    const api = `${EndpointConstants.LISTING}/listingApproval   `;
    return this.http.post(api,data);
  }

  getNotification(data:any):Observable<any> {
    const api = `${EndpointConstants.BaseUrl}messaging/public/notifications`;
    return this.http.post(api,data);
  }
  GetAllRecieverChats():Observable<any>{
    return this.http.get(`${EndpointConstants.BaseUrl}messaging/getUserNotification`)
  }
  notificationStatusUpdate(data:any):Observable<any> {
    const api = `${EndpointConstants.BaseUrl}listing/notificationStatusUpdate`;
    return this.http.post(api,data);
  }
  startPayment(data:any):Observable<any> {
    const api = `${EndpointConstants.BaseUrl}payment/stripePayment`;
    return this.http.post(api,data);
  }

  getVenderPaymentData():Observable<any> {
    const api = `${EndpointConstants.BaseUrl}payment/getVenderPaymentData`;
    return this.http.post(api,'');
  }

  SaveAddress(data:any):Observable<any> {
    const api = `${EndpointConstants.BaseUrl}users/addAddress`;
    return this.http.post(api,data);
  }
  updateAddress(data:any):Observable<any> {
    const api = `${EndpointConstants.BaseUrl}users/editAddress`;
    return this.http.post(api,data);
  }
  RemovedAddress(data:any):Observable<any> {
    const api = `${EndpointConstants.BaseUrl}users/addressDelete/${data}`;
    return this.http.delete(api,data);
  }
  getAddress():Observable<any> {
    const api = `${EndpointConstants.BaseUrl}users/getAddress`;
    return this.http.post(api,'');
  }
  getOrderList():Observable<any> {
    const api = `${EndpointConstants.BaseUrl}payment/userOrder`;
    return this.http.get(api);
  }
  getAllPayment():Observable<any> {
    const api = `${EndpointConstants.BaseUrl}payment/getAllPayment`;
    return this.http.get(api);
  }
  getIncomingOrderList():Observable<any> {
    const api = `${EndpointConstants.BaseUrl}payment/listingOrder`;
    return this.http.get(api);
  }
  getOrderDetails(data:any):Observable<any> {
    const api = `${EndpointConstants.BaseUrl}payment/getOrderDetails`;
    return this.http.post(api,data);
  }
  updateOrderStatus(data:any):Observable<any> {
    const api = `${EndpointConstants.BaseUrl}payment/updateOrderStatus`;
    return this.http.post(api,data);
  }
  copyListing(data:any): Observable<any> {
    const api = `${EndpointConstants.LISTING}/copyListing`;
    return this.http.post(api,data)
  }








  /* ESHOP */

  getAllEshops(options = {}): Observable<ListingInfoApi[]> {
    return this.http.get<ListingInfoApi[]>(EndpointConstants.ESTORE, { headers: this.headers, ...options });
  }

  createEshop(shopInfo: EShopInfo): Observable<any> {
    return this.http.post(EndpointConstants.CREATE_ESTORE, shopInfo);
  }

  updateEshop(shopInfo: EShopInfo, id): Observable<any> {
    const api = `${EndpointConstants.ESTORE}/${id}`;
    return this.http.put(api, shopInfo);
  }
  listingStatusUpdate(data) {
    const api = `${EndpointConstants.LISTING}/listingStatusUpdate`;
    return this.http.post(api,data);
  }
  deleteEshop(id: string): Observable<any> {
    const api = `${EndpointConstants.ESTORE}/${id}`;
    return this.http.delete(api);
  }
  updateEstoreStatus(data:any): Observable<any> {
    const api = `${EndpointConstants.ESTORE}/updateEstoreStatus`;
    return this.http.post(api,data);
  }
  SearchFollowers(text:string,value:any, mynet=false): Observable<any> {
    const api = `${EndpointConstants.ESTORE}/public-search?text=${text}&type=${value}&mynetwork=${mynet}`;
    return this.http.post(api,{});
  }
  followEstore(id:any):Observable<any>{
    const api = `${EndpointConstants.ESTORE}/follow`;
    return this.http.post(api,id);

  }

  publicEstore(id:any):Observable<any>{
    const api = `${EndpointConstants.ESTORE}/public/${id}`;
    return this.http.get(api);
  }

  publicNoToEstore(id:any):Observable<any>{
    const api = `${EndpointConstants.ESTORE}/public/${id}`;
    return this.http.get(api);
  }
  getCart(id:any):Observable<any>{
    const api = `${EndpointConstants.USER}/getCart/${id}`;
    return this.http.get(api);
  }
  
  unfollowEstore(id:any):Observable<any>{
    const api = `${EndpointConstants.ESTORE}/unfollow`;
    return this.http.post(api,id);
  }
  addCart(body:any):Observable<any>{
    const api = `${EndpointConstants.USER}/addCart`;
    return this.http.post(api,body);
  }
  updateEshopBlockStatus(id:any) {
    const api = `${EndpointConstants.ESTORE}/updateBlockStatus`;
    return this.http.post(api,id);
  }

  /*Merge Apis*/
  allFollowers():Observable<any>{
    const estores = this.http.get(`${EndpointConstants.USER}/followers/listingUsers`);
    const listings = this.http.get(`${EndpointConstants.USER}/followers/estoreUsers`);
    const users = this.http.get(`${EndpointConstants.USER}/followers/users`);
    return forkJoin([estores, listings, users])
  }
  allFollowing():Observable<any>{
    const estores = this.http.get(`${EndpointConstants.USER}/following/estores`);
    const listings = this.http.get(`${EndpointConstants.USER}/following/listings`);
    const users = this.http.get(`${EndpointConstants.USER}/following/users`);
    return forkJoin([estores, listings, users])
  }
  allUsers():Observable<any> {
    const usr1 = this.http.get(`${EndpointConstants.USER}/followers/users`);
    const usr2 = this.http.get(`${EndpointConstants.USER}/following/users`);
    return forkJoin([usr1, usr2])
  }

  saveScanData(type:string, id:string, ipResponse:any, user?:any) {
    const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const d = new Date();
    let day = weekday[d.getUTCDay()];
    // let scanTime = new Date().toLocaleTimeString('en-US',{hour12:false}).replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")+'-'+ new Date().toLocaleTimeString('en-US',{hour12:false}).replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
    const scanTime = new Date();

    // Format the current time in 24-hour format
    const formattedTime = scanTime.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });

    // Create the formatted time range with "AM/PM"
    const formattedTimeRange = `${formattedTime} ${scanTime.getHours() >= 12 ? 'PM' : 'AM'} - ${formattedTime} ${
      scanTime.getHours() >= 12 ? 'PM' : 'AM'
    }`;




    /// changes for est
    // const d = new Date();
    // let day = weekday[d.getUTCDay()]; // Assuming weekday is defined elsewhere
    
    // // Get the current time in UTC
    // const scanTime = new Date();
    
    // // Adjust the time to EST (UTC-5)
    // scanTime.setHours(scanTime.getHours() - 5);
    
    // // Format the current time in 24-hour format
    // const formattedTime = scanTime.toLocaleTimeString('en-US', {
    //   hour12: false,
    //   hour: '2-digit',
    //   minute: '2-digit',
    // });
    
    // // Create the formatted time range with "AM/PM" in EST
    // const formattedTimeRange = `${formattedTime} ${scanTime.getHours() >= 12 ? 'PM' : 'AM'} - ${formattedTime} ${
    //   scanTime.getHours() >= 12 ? 'PM' : 'AM'
    // }`;
    /// changes for est
    let deviceInfo = this.deviceService.getDeviceInfo();
    let data =  {
      ipAddress: ipResponse?.ip || '',
      latitude: ipResponse?.latitude || '',
      longitude: ipResponse?.longitude || '',
      product_id: id,
      product_type: type,
      browser: deviceInfo.browser,
      device: deviceInfo.deviceType,
      operating_system: deviceInfo.os,
      address: user?.location || '',
      country: ipResponse?.countryName || '',
      city: ipResponse?.city || '',
      repeat: (user?.id) ? true : false,
      user_id: user?.id,
      gender: user?.gender || '',
      dayEntry: day,
      time: formattedTimeRange,
    }
    this.SaveLocation(data).subscribe(res => {

    }, error => {
    })
  }

  getDeviceInfoSaved(type:string, id:string, user?:any) {
    if(!this.ipApiArray.length) {
      return
    }
    let apiKey = this.ipApiArray[0];
    // let apiKey = '17c89847-7183-49ea-a58f-28ea04751413' //https key
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('noAtuh', 'true');
    this.http.get(`https://apiip.net/api/check/?accessKey=${apiKey}`, { headers: httpHeaders }).subscribe((res) => {
      this.saveScanData(type, id, res, user)
    }, error => {
      this.expiredIpApis.push(this.ipApiArray.splice(0,1)[0]);
      this.getDeviceInfoSaved(type, id, user);
    });
  }
  public getJSON(): Observable<any> {
    return this.http.get("./assets/countries+states.json");
}

}
