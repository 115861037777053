import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@app/services/api.service';
import { NotificationService } from '@app/services/notification.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-forgot-passowrd',
  templateUrl: './forgot-passowrd.component.html',
  styleUrls: ['./forgot-passowrd.component.scss'],
})
export class ForgotPassowrdComponent implements OnInit {
  forgotPasswordForm: UntypedFormGroup;
  constructor(private apiService: ApiService, private router: Router, private notificationService: NotificationService,private cookieService: CookieService) {}

  ngOnInit(): void {
    this.forgotPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });

    
    const rememberedEmail = this.cookieService.get('rememberedEmail');

    if (rememberedEmail) {
      // Populate the email and password fields
      this.forgotPasswordForm.patchValue({
        email: rememberedEmail
      });
    }
  }

  forgetSubmit() {
    if (this.forgotPasswordForm.valid) {
      this.apiService.forgotPwd(this.forgotPasswordForm.value).subscribe(
        (res) => {
          if (res) {
            if(res.message == "No Account Registered With this Email")
            {this.notificationService.showErrorNotification(res.message,1000);}
            else{this.notificationService.showSuccessNotification('Check your mail for reset instructions',1000);this.router.navigate(['sign-in']);}
          }
        },(error) => {this.notificationService.showErrorNotification(error,1000);});
    }
  }

  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  get email() {return this.forgotPasswordForm.get('email');}
}
