import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '@app/material/material.module';
import { WindowTokenModule } from '@app/utils/window/window.provider';
import { CustomBreakPointsProvider } from '@config/custom-break-points.provider';
import { ClickKeydownModule } from '@utils/click-keydown/click-keydown.module';
import { AppRoutingModule } from './app-router/routes';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { InterceptorModule } from './interceptors/interceptor.module';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { UnAuthenticatedModule } from './unauthenticated/unauthenticated.module';
import { RequestInterceptor } from '../app/services/interceptor';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ShareOptionsComponent } from './share-options/share-options.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgxStripeModule } from 'ngx-stripe';
import { LineColumnAreaChartModule } from "./line-column-area-chart/line-column-area-chart.module";
import { FullCalendarModule } from '@fullcalendar/angular';
import { ViewQrcodeComponent } from './view-qrcode/view-qrcode.component';
import { ScanModalComponent } from './scan-modal/scan-modal/scan-modal.component';
import { NewHeaderComponent } from './new-header/new-header.component';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { CancelOrderPopupComponent } from './cancel-order-popup/cancel-order-popup.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { HomeModule } from './home/home.module';
import { MapViewModule } from './map-view/map-view.module';
import { LoadWalletModule } from './load-wallet/load-wallet.module';
import {CookieService} from 'ngx-cookie-service';
@NgModule({
  declarations: [AppComponent, HeaderComponent, SideNavigationComponent, 
    ShareOptionsComponent, ViewQrcodeComponent,ScanModalComponent, NewHeaderComponent, CancelOrderPopupComponent, InvoiceComponent, GoogleMapComponent],

    imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    InterceptorModule,
    MaterialModule,
    WindowTokenModule,
    NgxStripeModule.forRoot(environment.stripe_pk),
    FlexLayoutModule,
    ClickKeydownModule,
    UnAuthenticatedModule,
    HomeModule,
    SlickCarouselModule,
    ShareButtonsModule.withConfig({debug:true,}),
    ShareIconsModule,
    FullCalendarModule,
    // MapViewModule,
    LoadWalletModule,
    AppRoutingModule, // Always at the end!!
  ],
  exports:[ShareOptionsComponent,ScanModalComponent],
  providers: [
    CustomBreakPointsProvider, CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
