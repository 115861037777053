import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-share-options',
  templateUrl: './share-options.component.html',
  styleUrls: ['./share-options.component.scss']
})
export class ShareOptionsComponent {
  ShareUrl: any;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,) {
      this.ShareUrl = data.shareUrl
  }

}
