<div class="view-profile">
    <div class="demo-grid-1 mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
            <div class="content-top-heading">
                <h3>
                    <a *ngIf="user?.id" (click)="back()" style="cursor: pointer;color:black;position: relative;
                    top: 4px;">
                        <mat-icon>arrow_back_ios</mat-icon>
                    </a>Profile
                </h3>
            </div>
        </div>
    </div>
<ng-container *ngIf="user?.isVisible == true">
        <div class="demo-grid-1 mdl-grid">
            <div class="mdl-cell mdl-cell--12-col">
                <div class="white-heading">
                    <div class="flex-view">
                        <div class="left-flex-view">
                            <img class="eshop_banner_img" [src]="user?.profilePicture != '' ? user?.profilePicture : 'assets/images/profilepic2.png'" loading="lazy" alt="profilePicture">
                            <div class="eshop_flx">
                                <p class="eshop_flx_1">{{ user?.firstName }} {{ user?.lastName }}  ( {{ user?.uniqueID | uppercase }} )</p>
                                <p class="eshop_flx_2" *ngIf="user?.title">{{ user?.title }}</p>
                            </div>
                        </div>
                        <div class="right-flex-view">
                            <div class="simple-data" *ngIf="currentUser.role !== 'Admin'">
                                <ul class="f-c-s-button">
                                    <ng-container *ngIf="!userItself">
                                        <li><button class="bk-grey" *ngIf="!isFollowingUser" (click)="followButton(user)">Follow</button></li>
                                        <li><button class="bk-grey" *ngIf="isFollowingUser" (click)="unfollowButton(user)">UnFollow</button></li>
                                        <li><button class="bk-grey" (qClickKeydown)="navigateChat()" *ngIf="currentUser?.role == 'User' && session?.authTokenValue">Chat</button></li>
                                    </ng-container>
                                    <li><a mat-button href="javascript:void(0)" (qClickKeydown)="openShareOptions(user)" matTooltip="Share"><mat-icon matPrefix>share</mat-icon></a></li>
                                </ul>
                            </div>
                            <div class="drop-dwn" *ngIf="currentUser">
                                <a href="javascript:void(0)" [matMenuTriggerFor]="menu" matTooltip="Options"><span></span><span></span><span></span></a>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (qClickKeydown)="downloadCode(user)" [disabled]="loading">
                                      <mat-icon>download</mat-icon>
                                      <span>Download QrCode</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div class="flex-view-1">
                        <p class="flex-view-1-p"><span>Unique ID</span><span>{{ user?.uniqueID | uppercase }}</span>
                            <img src="{{qrCode}}" loading="lazy" class="bar-cd web" alt="qrCode">      
                        </p>
                        <img src="{{qrCode}}" loading="lazy" class="bar-cd mob" alt="qrCode">      
                        <ul class="d-flex flex-wrap">
                            <ng-container *ngIf="user?.isGender">
                                <li>
                                    <span class="normal-font">{{ user?.gender | titlecase }}</span>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="user?.isBirthday">
                                <li>
                                    <span class="normal-font">{{ user?.birthDate |date:'MM/dd/yyyy' }}{{age ? '('+age+' yrs'+')' : ''}}</span>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="user?.isEmail">
                                <li>
                                    <span class="normal-font">{{ user?.email }}</span>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="user?.isLocation">
                                <li>
                                    <span class="normal-font">{{ user?.location }}</span>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="user?.isContact">
                                <li>
                                    <span class="normal-font">{{ user?.phoneNo?.internationalNumber }}</span>
                                </li>
                            </ng-container>
                        </ul>    
                    </div>
                    <div class="last-dv">
                        <div class="media-body-1">
                            <div class="media-body-right-1">
                                <ng-container *ngIf="user?.isBio == true">
                                    <p class="bio-1">Bio:</p>
                                    <p class="bio-2 m-0">{{ user?.bio }}</p>
                                </ng-container>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="mar-top-50">
                    
                    <div class="demo-grid-1 mdl-grid web">
                        <div class="mdl-cell mdl-cell--6-col card-1" [ngClass]="{ 'empty-card': publicList.length === 0}">
                            <p class="eshop_flx_1">Qtag</p>
                            <div class="left-cell-1 card-2">
                                <ul>
                                    <li *ngFor="let item of publicList">
                                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" 
                                        (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                                        (beforeChange)="beforeChange($event)">
                                        <div ngxSlickItem *ngFor="let slide of item?.photos" class="slide">
                                            <img [src]="slide?.photoUrl ?? 'assets/images/nivia.png'" loading="lazy" style="cursor: pointer;" (qClickKeydown)="listRoute(item)" class="nivia" alt="listPics">
                                        </div>
                                    </ngx-slick-carousel>
                                        <p class="bow" style="cursor: pointer;" (qClickKeydown)="listRoute(item)">{{item?.listing?.listingName ?? 'Bow & Arrows'}}</p>
                                        <div class="Follow-flx">
                                            <p class="dolar-1">{{item?.listing?.price != 'undefined' ? 'CAD ' +item?.listing?.price : ''}}</p>
                                            <button class="Follow-button" (qClickKeydown)="listRoute(item)">View</button>
                                        </div>
                                    </li>
                                </ul>
                                <ng-container *ngIf="!publicList?.length">
                                    <p style="text-align: center;">No Data Found</p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="mdl-cell mdl-cell--6-col card-1" [ngClass]="{ 'empty-card': list.length === 0}">
                            <p class="eshop_flx_1">Eshop</p>
                            <div class="left-cell-1 card-2">
                                <ul>
                                    <li *ngFor="let item of list">
                                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" 
                                        (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                                        (beforeChange)="beforeChange($event)">
                                        <div ngxSlickItem *ngFor="let slide of item?.eStorePhoto" class="slide">
                                            <img [src]="slide?.photoUrl ?? 'assets/images/nivia.png'" style="cursor: pointer;" loading="lazy" (qClickKeydown)="estoreRoute(item)" class="nivia" alt="eshopPic">
                                        </div>
                                    </ngx-slick-carousel>
                                        <p class="bow" style="cursor: pointer;" (qClickKeydown)="estoreRoute(item)">{{item?.eStore?.businessName ?? 'Bow & Arrows'}}</p>
                                        <div class="Follow-flx">
                                            <button class="Follow-button" (qClickKeydown)="estoreRoute(item)">View</button>
                                        </div>
                                    </li>
                                </ul>
                                <ng-container *ngIf="!list?.length">
                                    <p style="text-align: center;">No Data Found</p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
    
                    <div class="demo-grid-1 mdl-grid mob">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <p class="eshop_flx_1">Qtag</p>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                            
                                <div class="left-cell-1">
                                    <ul>
                                        <li *ngFor="let item of publicList">
                                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" 
                                            (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                                            (beforeChange)="beforeChange($event)">
                                            <div ngxSlickItem *ngFor="let slide of item?.photos" class="slide">
                                                <img [src]="slide?.photoUrl ?? 'assets/images/nivia.png'" loading="lazy" (qClickKeydown)="listRoute(item)" class="nivia" alt="listPics">
                                            </div>
                                        </ngx-slick-carousel>
                                            <p class="bow" style="cursor: pointer;" (qClickKeydown)="listRoute(item)">{{item?.listing?.listingName ?? 'Bow & Arrows'}}</p>
                                            <div class="Follow-flx">
                                                <p class="dolar-1">{{item?.listing?.price != 'undefined' ? 'CAD ' +item?.listing?.price : ''}}</p>
                                                <button class="Follow-button" (qClickKeydown)="listRoute(item)">View</button>
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-container *ngIf="!publicList?.length">
                                        <p style="text-align: center;">No Data Found</p>
                                    </ng-container>
                                </div>
                            </mat-expansion-panel>
    
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <p class="eshop_flx_1">E-Shop</p>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="left-cell-1">
                                    <ul>
                                        <li *ngFor="let item of list">
                                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" 
                                            (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                                            (beforeChange)="beforeChange($event)">
                                            <div ngxSlickItem *ngFor="let slide of item?.eStorePhoto" class="slide">
                                                <img [src]="slide?.photoUrl ?? 'assets/images/nivia.png'" style="cursor: pointer;" loading="lazy" style="cursor: pointer;" (qClickKeydown)="estoreRoute(item)" class="nivia" alt="eshopPics">
                                            </div>
                                        </ngx-slick-carousel>
                                            <p class="bow" style="cursor: pointer;" (qClickKeydown)="estoreRoute(item)">{{item?.eStore?.businessName ?? 'Bow & Arrows'}}</p>
                                            <div class="Follow-flx">
                                                <button class="Follow-button" (qClickKeydown)="estoreRoute(item)">View</button>
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-container *ngIf="!list?.length">
                                        <p style="text-align: center;">No Data Found</p>
                                    </ng-container>
                                </div>
                              </mat-expansion-panel>
                          </mat-accordion>
                    </div>
                </div>
            </div>
        </div>   
</ng-container>


<ng-container *ngIf="user?.isVisible == false">
        <div class="demo-grid-1 mdl-grid blur">
            <div class="mdl-cell mdl-cell--12-col">
                <div class="white-heading">
                    <div class="flex-view">
                        <div class="left-flex-view">
                            <img class="eshop_banner_img" [src]="'assets/images/profilepic2.png'" loading="lazy" alt="profilePicture">
                            <div class="eshop_flx">
                                <p class="eshop_flx_1">{{ 'test' }}  ( {{ 'uniqueId' }} )</p>
                                <p class="eshop_flx_2">{{ 'title' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-view-1">
                        <ul class="d-flex flex-wrap">
                            <ng-container *ngIf="user?.isGender">
                                <li>
                                    <span>Gender:</span>
                                    <span class="normal-font">{{ 'male' }}</span>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="user?.isBirthday">
                                <li>
                                    <span>Birthdate:</span>
                                    <span class="normal-font">{{ '01/01/2000' }}</span>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="user?.isEmail">
                                <li>
                                    <span>Email:</span>
                                    <span class="normal-font">{{ 'test@yopmail.com' }}</span>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="user?.isLocation">
                                <li>
                                    <span>Location:</span>
                                    <span class="normal-font">{{ 'Earth' }}</span>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="user?.isContact">
                                <li>
                                    <span>Contact:</span>
                                    <span class="normal-font">{{ '+1 9999999999' }}</span>
                                </li>
                            </ng-container>
                        </ul>    
                    </div>
                    <div class="last-dv">
                        <div class="media-body-1">
                            <div class="media-body-right-1">
                                <ng-container *ngIf="user?.isBio == true">
                                    <p class="bio-1">Bio:</p>
                                    <p class="bio-2 m-0">{{ 'lorem ipsum' }}</p>
                                </ng-container>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="mar-top-50">
                    
                    <div class="demo-grid-1 mdl-grid web">
                        <div class="mdl-cell mdl-cell--6-col card-1" [ngClass]="{ 'empty-card': publicList.length === 0}">
                            <p class="eshop_flx_1">Qtag</p>
                            <div class="left-cell-1 card-2">
                                <ul>
                                    <li *ngFor="let item of publicList">
                                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" 
                                        (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                                        (beforeChange)="beforeChange($event)">
                                        <div ngxSlickItem class="slide">
                                            <img [src]="'assets/images/nivia.png'" loading="lazy" style="cursor: pointer;" (qClickKeydown)="listRoute(item)" class="nivia" alt="listPics">
                                        </div>
                                    </ngx-slick-carousel>
                                        <p class="bow" style="cursor: pointer;" (qClickKeydown)="listRoute(item)">{{'Bow & Arrows'}}</p>
                                        <div class="Follow-flx">
                                            <p class="dolar-1">{{'No Price'}}</p>
                                            <button class="Follow-button">View</button>
                                        </div>
                                    </li>
                                </ul>
                                <ng-container *ngIf="!publicList?.length">
                                    <p style="text-align: center;">No Data Found</p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="mdl-cell mdl-cell--6-col card-1" [ngClass]="{ 'empty-card': list.length === 0}">
                            <p class="eshop_flx_1">Eshop</p>
                            <div class="left-cell-1 card-2">
                                <ul>
                                    <li *ngFor="let item of list">
                                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" 
                                        (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                                        (beforeChange)="beforeChange($event)">
                                        <div ngxSlickItem class="slide">
                                            <img [src]="'assets/images/nivia.png'" loading="lazy" (qClickKeydown)="estoreRoute(item)" class="nivia" alt="eshopPic">
                                        </div>
                                    </ngx-slick-carousel>
                                        <p class="bow" style="cursor: pointer;" (qClickKeydown)="estoreRoute(item)">{{'Bow & Arrows'}}</p>
                                        <div class="Follow-flx">
                                            <button class="Follow-button" (qClickKeydown)="estoreRoute(item)">View</button>
                                        </div>
                                    </li>
                                </ul>
                                <ng-container *ngIf="!list?.length">
                                    <p style="text-align: center;">No Data Found</p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
    
                    <div class="demo-grid-1 mdl-grid mob">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <p class="eshop_flx_1">Qtag</p>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                            
                                <div class="left-cell-1">
                                    <ul>
                                        <li *ngFor="let item of publicList">
                                            <ngx-slick-carousel class="carousel"  #slickModal="slick-carousel" [config]="slideConfig" 
                                            (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                                            (beforeChange)="beforeChange($event)">
                                            <div ngxSlickItem  class="slide">
                                                <img [src]="'assets/images/nivia.png'" loading="lazy" (qClickKeydown)="listRoute(item)" class="nivia" alt="listPics">
                                            </div>
                                        </ngx-slick-carousel>
                                            <p class="bow" style="cursor: pointer;" (qClickKeydown)="listRoute(item)">{{'Bow & Arrows'}}</p>
                                            <div class="Follow-flx">
                                                <p class="dolar-1">{{'No Price'}}</p>
                                                <button class="Follow-button" (qClickKeydown)="listRoute(item)">View</button>
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-container *ngIf="!publicList?.length">
                                        <p style="text-align: center;">No Data Found</p>
                                    </ng-container>
                                </div>
                            </mat-expansion-panel>
    
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <p class="eshop_flx_1">E-Shop</p>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="left-cell-1">
                                    <ul>
                                        <li *ngFor="let item of list">
                                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" 
                                            (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                                            (beforeChange)="beforeChange($event)">
                                            <div ngxSlickItem class="slide">
                                                <img [src]="'assets/images/nivia.png'" loading="lazy" style="cursor: pointer;" (qClickKeydown)="estoreRoute(item)" class="nivia" alt="eshopPics">
                                            </div>
                                        </ngx-slick-carousel>
                                            <p class="bow" style="cursor: pointer;" (qClickKeydown)="estoreRoute(item)">{{'Bow & Arrows'}}</p>
                                            <div class="Follow-flx">
                                                <button class="Follow-button" (qClickKeydown)="estoreRoute(item)">View</button>
                                            </div>
                                        </li>
                                    </ul>
                                    <ng-container *ngIf="!list?.length">
                                        <p style="text-align: center;">No Data Found</p>
                                    </ng-container>
                                </div>
                              </mat-expansion-panel>
                          </mat-accordion>
                    </div>
                </div>
            </div>
        </div>  
    <div class="notPub">
        <p>Profile is not Visible for the public</p>
    </div> 
</ng-container>

</div>


<ng-container *ngIf="loading">
    <div class="spin">
        <mat-spinner>Downloading...</mat-spinner>
    </div>
</ng-container>

<div class="contBlock" #contentToConvert id="contentToConvert" style="display: flex;flex-direction: column;align-items: center;margin-top: -30000px; position: absolute;padding: 10px 0;">
    <img src="/assets/logo.png" alt="logo" aria-label="logo" style="max-width: 100px;margin-bottom: 10px;padding-bottom: 0;"/>
    <img src="{{downloadQr}}" alt="qrCode" class="qrimg">
    <a class="qrName" href="javascript:void(0)">{{download?.firstName + ' ' + download?.lastName |titlecase}} ({{download?.uniqueID}})</a>
</div>

<div class="contBlock" id="contentToConvert" style="position: absolute; width: 60%; margin-top:-20000px;">
    <img src="/assets/logo.png" alt="logo" aria-label="logo" style="max-width: 100px;margin-bottom: 10px;"/>
    <img src="{{downloadQr}}" alt="qrCode" class="qrimg">
    <a class="qrName" href="javascript:void(0)" *ngIf="download">{{download?.firstName+' '+download?.lastName}} ({{download?.uniqueID}})</a>
</div>

<!--------------------------Qtag Not Active modal--------------------------->
<ng-template #activatelistPopup let-modal>
    <div class="popup">
      <div class="modal-header right-fix">
        <h4 class="modal-title" id="modal-basic-title">Warning!</h4>
        <button mat-button mat-dialog-close><span>&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="wallet-activate-msg">
          <p>Your Qtag has been blocked by the admin.
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button mat-raised-button color="dark" class="submit_btn mr-1" type="button" (click)="goback()">Ok</button>
      </div>
    </div>
  </ng-template>
  <!--------------------------Eshop Not Active modal--------------------------->
<ng-template #activateEshopPopup let-modal>
    <div class="popup">
      <div class="modal-header right-fix">
        <h4 class="modal-title" id="modal-basic-title">Warning!</h4>
        <button mat-button mat-dialog-close><span>&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="wallet-activate-msg">
          <p>Your Eshop has been blocked by the admin.
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button mat-raised-button color="dark" class="submit_btn mr-1" type="button" (click)="goback()">Ok</button>
      </div>
    </div>
  </ng-template>