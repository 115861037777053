<mat-sidenav-container *ngIf="token else unauth">
    <mat-sidenav [mode]="mode" [opened]="isopened">
        <div [routerLink]="'/'" class="logoSet">
            <img src="/assets/logo.svg" loading="lazy" alt="logo" aria-label="logo"/>
        </div>
        <mat-nav-list>
            <mat-list-item *ngFor="let menuitem of menuItems" (click)="onClick(menuitem.state)">
                <a class="font-less" routerLinkActive="active" [routerLink]="[menuitem.state]">
                    <mat-icon>{{ menuitem.icon }}</mat-icon>
                    <span>{{ menuitem.name }}</span>
                </a>
                <!-- <a class="font-less" [routerLink]="[menuitem.state]" routerLinkActive="active" (qClickKeydown)="toggle()" fxFlex fxLayoutAlign="start center">
                    <mat-icon>{{ menuitem.icon }}</mat-icon>
                    <span>{{ menuitem.name }}</span>
                </a> -->
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-header (toggleMenu)="toggle()"></app-header>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #unauth>
    <app-new-header *ngIf="noHeaderRoutes.includes(this.currentRouteBase)"></app-new-header>
    <router-outlet></router-outlet>
</ng-template>