import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstoreQrCodeComponent } from '../estore-qr-code/estore-qr-code/estore-qr-code.component';
import { EstoreQrCodeRoutingModule } from './estore-qr-code-routing.module';
import { MaterialModule } from '@app/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ClickKeydownModule } from '@app/utils/click-keydown/click-keydown.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';


@NgModule({
  declarations: [EstoreQrCodeComponent],
  imports: [
    CommonModule,
    EstoreQrCodeRoutingModule,
    MaterialModule,ClickKeydownModule, FlexLayoutModule, ReactiveFormsModule,MaterialModule, SlickCarouselModule
  ],
  exports:[EstoreQrCodeComponent]
})
export class EstoreQrCodeModule { }
