<div class="my-eshop">

    <div class="demo-grid-1 mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
            <div class="content-top-heading">
                <h3>
                    <a *ngIf="user?.id" (click)="back()" style="cursor: pointer;color:black;position: relative;
                    top: 4px;">
                        <mat-icon>arrow_back_ios</mat-icon>
                    </a>Eshop View</h3>
            </div>
        </div>
    </div>

    <ng-container *ngIf="notPublish == false">
    <div class="demo-grid-1 mdl-grid">
        <div class="mdl-cell mdl-cell--8-col">
            <div class="eshop-left">
                <div class="image-eshop">
                    <img class="eshop-full-img" src="{{ picUrl ?? ''}}" *ngIf="picUrl" loading="lazy" alt="eshopPic" aria-label="eshopPic">
                </div>
                <div class="SilverSun">
                    <div class="Silver-1">
                        <p class="SilverSunP">{{publicList?.eStore?.businessName}} {{publicList?.eStore?.estoreUniqueID ? '('+publicList?.eStore?.estoreUniqueID+')': ''}}</p>
                    </div>
                       <ul class="Chat-ul">
                        <li *ngIf="currentUser?.role == 'User' && currentUser?.id != publicList?.eStore?.user && isFollowingCurrentList"><button (click)="unFollowButton(publicList)" style="cursor: pointer;line-height: 0.22;">Unfollow</button></li>
                        <li *ngIf="currentUser?.role == 'User' && currentUser?.id != publicList?.eStore?.user && !isFollowingCurrentList"><button (click)="followButton(publicList)" style="cursor: pointer;line-height: 0.22;">Follow</button></li>
                        <li *ngIf="currentUser?.role == 'User' && currentUser?.id != publicList?.eStore?.user"><button (click)="navigateChat('eshop','')" style="cursor: pointer;line-height: 0.22;">Chat</button></li>
                    </ul>
                </div>
                <div class="listing-div">
                    <div class="listing-top">
                        <p>PRODUCT</p>
                        <div class="search-listing">
                            <input type="search" class="search-input" placeholder="Search for services">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </div>
                    </div>
                    <div class="input-product">
                        <input type="text" class="pro-input" placeholder="PRODUCT">
                    </div>
                    <div class="eshop-repet" *ngFor="let item of ShowlistingArray">
                        <div class="eshop-convert" style="cursor: pointer;" (click)="goToList(item?.id ?? item?._id)">
                            <img src="{{item?.listingPhotos[0]?.photoUrl ?? 'assets/images/img-temp.png'}}" loading="lazy" class="img-temp" alt="listPic" aria-label="listPic">
                            <p class="PrivateBooking">{{item?.listingName}}</p>
                        </div>
                        <ul class="Chat-ul">
                            <li><button style="padding: 5px 5px 15px 5px;" *ngIf="currentUser?.role == 'User' && (item?.id || item?._id) && !item?.addedInCart && currentUser?.id !== item?.user && item?.price != 'undefined' && item?.user != '6630d7e7412ae5cb464c7116'" (click)="addToCart(item,publicList)">Add to Cart</button></li>
                            <li><button style="padding: 5px 5px 15px 5px;" *ngIf="item?.addedInCart && currentUser?.id !== item?.user" routerLink="/cart">Go to Cart</button></li>
                            <li><button (click)="navigateChat('qtag',item)" style="cursor: pointer;line-height: 0.22;" *ngIf="currentUser?.role == 'User' && currentUser?.id != publicList?.eStore?.user">Chat</button></li>
                            <li><p class="eshop-dolar">{{item?.price !='undefined' ? 'CAD '+item?.price : ''}}</p></li>
                        </ul>

                    </div>
                    <ng-container *ngIf="ShowlistingArray?.length">
                    <div class="show-more" *ngIf="ShowlistingArray?.length != estoreListing?.length">
                        <a href="javascript:void(0)" class="ShowMore" (click)="showMore()">Show More</a>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="!ShowlistingArray?.length">
                        <div class="eshop-ShowErr">
                            <div class="eshop-convert">
                                <p class="PrivateBooking">No Data found</p>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col details">
            <div class="eshop-right">
                <div class="maptop">
                    <p class="maptop-p">Details on Map</p>
                    <a class="maptop-a" href="javascript:void(0)" [matMenuTriggerFor]="mapMenu">Show All List</a>
                    <mat-menu #mapMenu="matMenu">
                        <div mat-menu-item>
                          <h4 style="text-align: center;">Map Detail</h4>
                          <div>
                            <p *ngIf="publicList?.eStore?.location?.locationName"><b>Location Name: </b>{{publicList?.eStore?.location?.locationName}}</p>
                            <p *ngIf="publicList?.eStore?.address"><b>Address: </b>{{publicList?.eStore?.address}}</p>
                          </div>
                        </div>
                    </mat-menu>
                </div>
                <div class="maptop-img">
                    <google-map #myGoogleMap height="250px" width="100%" [zoom]="zoom" [center]="center" [options]="options" (mapClick)="eventHandler($event,'mapClick')" (mapDblclick)="eventHandler($event,'mapDblclick')" (mapDrag)="eventHandler($event,'mapDrag')" (mapDragend)="eventHandler($event,'mapDragend')"
                        (mapRightclick)="eventHandler($event,'mapRightclick')" (positionChanged)="eventHandler($event,'marker_positionChanged')" (zoomChanged)="eventHandler($event,'zoomChanged')">
                        <map-marker #markerElem="mapMarker" *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options" (mapClick)="openInfo(markerElem, marker.info)">
                        </map-marker>

                        <map-info-window>{{ infoContent }}</map-info-window>
                    </google-map>
                </div>
                <div class="gold-map">
                    <div class="media-gold">
                        <div class="gold-content">
                            <p class="gold-content-p">{{ publicList?.eStore?.location?.locationName ?? 'Annexe Underdoog'}}</p>
                            <p class="gold-content-p-1" *ngIf="addressHead">
                                <ng-container *ngFor="let adrs of [0,1,2,3,4]">
                                    <i *ngIf="addressHead[adrs]"> {{adrs !== 0 ? ',' : ''}} {{addressHead[adrs]}}</i>
                                </ng-container>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="about">
                    <div class="about-3">
                        <p class="about-1">Owner</p>
                        <p class="about-2" (qClickKeydown)="navigateProfile()" style="cursor: pointer;">{{ userData?.uniqueID }}</p>
                    </div>
                    <div class="about-3">
                        <p class="about-1">About Us</p>
                        <p class="about-2"><span>{{ publicList?.eStore?.businessDescription ?? 'Hardwork, Dedication & Good Vibe'}}</span> <span>Mobile : {{publicList?.eStore?.mobileNo.internationalNumber ?? '871.567.4877'}}</span>
                        </p>
                    </div>
                    <div class="about-4">
                        <p class="about-1">Contact & Business Hours</p>
                        <div class="clall-flex d-flex justify-content-between align-items-center flex-wrap">
                            <p class="about-2"><span>Mobile : {{publicList?.eStore?.mobileNo.internationalNumber ?? '213 051 832'}}</span></p>
                        </div>
                    </div>
                </div>
                <div class="other-details">
                    <div class="sunday-flex">
                        <ul *ngFor="let item of estoreTimimg">
                            <li>
                                <p class="thin-font">{{item?.day}}</p>
                                <p class="bold-font" *ngIf="item?.endTime != ''">{{item?.startTime}} - {{item?.endTime}}</p>
                                <p class="bold-font" *ngIf="item?.endTime == ''">{{item?.startTime}}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="barcode-flx">
                        <img class="barcode-flx-img" [src]="qrCode  ?? 'assets/images/barcode.png'" loading="lazy" alt="qrCode" aria-label="qrCode">
                        <ul class="ul-last">
                            <li><a href="javascript:void(0)" [disabled]="loading == true" (qClickKeydown)="downloadCode(publicList)" [disabled]="loading"><i class="fa-solid fa-download"></i> Download</a></li>
                            <li><a href="javascript:void(0)" (qClickKeydown)="openShareOptions(publicList)"><i class="fa-solid fa-share"></i> Share</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </ng-container>

    <ng-container *ngIf="notPublish == true">
        <div class="demo-grid-1 mdl-grid blur">
            <div class="mdl-cell mdl-cell--8-col">
                <div class="eshop-left">
                    <div class="image-eshop">
                        <img class="eshop-full-img" src="{{ picUrl ?? ''}}" loading="lazy" alt="profilePicture" aria-label="profilePicture">
                    </div>
                    <div class="SilverSun">
                        <div class="Silver-1">
                            <p class="SilverSunP">{{publicList?.eStore?.businessName ?? 'No Name'}}</p>
                        </div>
                    </div>
                    <div class="listing-div">
                        <div class="listing-top">
                            <p>PRODUCT</p>
                            <div class="search-listing">
                                <input type="search" class="search-input" placeholder="Search for services">
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </div>
                        </div>
                        <div class="input-product">
                            <input type="text" class="pro-input" placeholder="PRODUCT">
                        </div>
                        <div class="eshop-repet" *ngFor="let item of ShowlistingArray">
                            <div class="eshop-convert" style="cursor: pointer;" (click)="goToList(item?.id ?? item?._id)">
                                <img src="{{item?.listingPhotos[0]?.photoUrl ?? 'assets/images/img-temp.png'}}" loading="lazy" class="img-temp" alt="listPic" aria-label="listPic">
                                <p class="PrivateBooking">{{item?.listingName}}</p>
                            </div>
                            <ul class="Chat-ul">
                                <li><button style="padding: 5px 5px 15px 5px;">Add to Cart</button>
                                    <button style="padding: 5px 5px 15px 5px;">Go to Cart</button></li>
                                <li>
                                <li>
                                    <p class="eshop-dolar">{{item?.price !='undefined' ? 'CAD '+item?.price : ''}}</p>
                                </li>
                            </ul>
    
                        </div>
    
                        <div class="show-more" *ngIf="ShowlistingArray?.length != estoreListing?.length">
                            <a href="javascript:void(0)" class="ShowMore" (click)="showMore()">Show More</a>
                        </div>
                        <ng-container *ngIf="!ShowlistingArray?.length">
                            <div class="eshop-ShowErr">
                                <div class="eshop-convert">
                                    <p class="PrivateBooking">No Data found</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="mdl-cell mdl-cell--4-col details">
                <div class="eshop-right">
                    <div class="maptop">
                        <p class="maptop-p">Details on Map</p>
                        <a class="maptop-a" href="javascript:void(0)" [matMenuTriggerFor]="mapMenu">Show All List</a>
                        <mat-menu #mapMenu="matMenu">
                            <div mat-menu-item>
                              <h4 style="text-align: center;">Map Detail</h4>
                              <div>
                                <p *ngIf="publicList?.eStore?.location?.locationName"><b>Location Name: </b>{{publicList?.eStore?.location?.locationName}}</p>
                                <p *ngIf="publicList?.eStore?.address"><b>Address: </b>{{publicList?.eStore?.address}}</p>
                              </div>
                            </div>
                        </mat-menu>
                    </div>
                    <div class="maptop-img">
                        <google-map #myGoogleMap height="200px" width="100%" [zoom]="zoom" [center]="center" [options]="options" (mapClick)="eventHandler($event,'mapClick')" (mapDblclick)="eventHandler($event,'mapDblclick')" (mapDrag)="eventHandler($event,'mapDrag')" (mapDragend)="eventHandler($event,'mapDragend')"
                            (mapRightclick)="eventHandler($event,'mapRightclick')" (positionChanged)="eventHandler($event,'marker_positionChanged')" (zoomChanged)="eventHandler($event,'zoomChanged')">
                            <map-marker #markerElem="mapMarker" *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options" (mapClick)="openInfo(markerElem, marker.info)">
                            </map-marker>
    
                            <map-info-window>{{ infoContent }}</map-info-window>
                        </google-map>
                    </div>
                    <div class="gold-map">
                        <div class="media-gold">
                            <div class="gold-content">
                                <p class="gold-content-p">{{ publicList?.eStore?.location?.locationName ?? 'Annexe Underdoog'}}</p>
                                <p class="gold-content-p-1">
                                    <ng-container *ngFor="let adrs of [0,1,2,3,4]">
                                        <i *ngIf="addressHead[adrs]"> {{adrs !== 0 ? ',' : ''}} {{addressHead[adrs]}}</i>
                                    </ng-container>
                                </p>
                            </div>
                        </div>
                        <a class="span-dot" href="javascript:void(0)" [matMenuTriggerFor]="menu"><span></span><span></span><span></span></a>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (qClickKeydown)="downloadCode(publicList)">
                              <mat-icon>download</mat-icon>
                              <span>Download</span>
                            </button>
                        </mat-menu>
                    </div>
                    <div class="about-3">
                        <p class="about-1">About Us</p>
                        <p class="about-2"><span>{{ publicList?.eStore?.businessDescription ?? 'Hardwork, Dedication & Good Vibe'}}</span> <span>Mobile : {{publicList?.eStore?.mobileNo.internationalNumber ?? '871.567.4877'}}</span>
                        </p>
                    </div>
                    <div class="about-4">
                        <p class="about-1">Contact & Business Hours</p>
                        <div class="clall-flex d-flex justify-content-between align-items-center flex-wrap">
                            <p class="about-2"><span>Mobile : {{publicList?.eStore?.mobileNo.internationalNumber ?? '213 051 832'}}</span></p>
                        </div>
                    </div>
                    <div class="sunday-flex">
                        <ul *ngFor="let item of estoreTimimg">
                            <li>
                                <p class="thin-font">{{item?.day}}</p>
                                <p class="bold-font" *ngIf="item?.endTime != ''">{{item?.startTime}} - {{item?.endTime}}</p>
                                <p class="bold-font" *ngIf="item?.endTime == ''">{{item?.startTime}}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="barcode-flx">
                        <img class="barcode-flx-img" [src]="qrCode  ?? 'assets/images/barcode.png'" loading="lazy" alt="qrCode" aria-label="qrCode">
                    </div>
                    <ul class="ul-last">
                        <li><a href="javascript:void(0)" [disabled]="loading == true" (qClickKeydown)="downloadCode(publicList)"><i class="fa-solid fa-download"></i> Download</a></li>
                        <li><a href="javascript:void(0)" (qClickKeydown)="openShareOptions(publicList)"><i class="fa-solid fa-share"></i> Share</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="notPub">
            <p>Estore not published by owner</p>
        </div>
        </ng-container>
</div>
<ng-container *ngIf="loading">
    <div class="spin">
        <mat-spinner>Downloading...</mat-spinner>
    </div>
</ng-container>

<div class="contBlock" #contentToConvert id="contentToConvert" style="display: flex;flex-direction: column;align-items: center;margin-top: -200000px; position: absolute;padding: 10px 0;">
    <img src="/assets/logo.png" style="max-width: 100px;margin-bottom: 10px;padding-bottom: 0;" alt="logo" aria-label="logo"/>
    <img src="{{qrCode}}" alt="qrCode" aria-label="qrCode" class="qrimg">
    <a class="qrName" href="javascript:void(0)">{{download?.businessName+'('+download?.estoreUniqueID+')'}}</a>
</div>

<div class="contBlock" id="contentToConvert" style="position: absolute; width: 60%; margin-top:-20000px;">
    <img src="/assets/logo.png" alt="logo" aria-label="logo" style="max-width: 100px;margin-top: 10px;padding-bottom: 0;margin-bottom: 10px;"/>
    <img src="{{downloadQr}}" alt="qrCode" aria-label="qrCode" class="qrimg">
    <a class="qrName" href="javascript:void(0)">{{download?.eStore?.businessName+'('+download?.eStore?.estoreUniqueID+')'}}</a>
</div>