<div class="right-fix" mat-dialog-actions>
    <h1 mat-dialog-title tabindex="-1" cdkFocusInitial align="center">Share the Url</h1>
    <button mat-button mat-dialog-close><span>&times;</span></button>
</div>
<div mat-dialog-content class="d-flex flex-wrap justify-content-center">
    <share-buttons show="11" [theme]="'circles-dark'" class="dark"
        [include]="['copy', 'facebook', 'email','telegram', 'twitter', 'whatsapp']"
        [showIcon]="true"
        [url]=ShareUrl
        [autoSetMeta]="true"
        [showText]="true"
        prop="{copy: {showText:'Copied'}}"
        style="--sb-font-size: 16px; font-size: var(--sb-font-size);">
    </share-buttons>
</div>