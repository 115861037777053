import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent {
  coords:coordinates;
  isLocationChoosen:boolean = false;
  zoom = 12;  maxZoom = 20;  minZoom = 5;
  mapWidth: string = window.innerWidth <= 600 ? '250px' : '400px';
  center!: google.maps.LatLngLiteral;
  locationData:any;
  options: google.maps.MapOptions = {
    zoomControl: true, scrollwheel: true, disableDoubleClickZoom: false,
    mapTypeControlOptions: {
      mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID, google.maps.MapTypeId.SATELLITE]
    }, // here´s the array of controls
    disableDefaultUI: true, // a way to quickly hide all controls
    mapTypeControl: true,streetViewControl: false,fullscreenControl: true,rotateControl: false,mapTypeId: 'roadmap',maxZoom: this.maxZoom,minZoom: this.minZoom
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any){
    if(data){
      if( data.lat || data.lng ){
        this.coords = { lat: Number(data.lat), lng: Number(data.lng) };
        this.center = { lat: Number(data.lat), lng: Number(data.lng) };
        this.isLocationChoosen = true;
      }
      else{
        this.getMyLocation();
      }
    }
  }

  mapClicked(event:any){
    this.coords = { lat: event.latLng.lat(), lng: event.latLng.lng() }
    this.isLocationChoosen = true;
    this.getPlaceName(this.coords.lat, this.coords.lng);
  }

  private getPlaceName(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat: lat, lng: lng };

    geocoder.geocode({ 'location': latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const formatteAddress = results[0].address_components;
          let obj = {}
          formatteAddress.forEach(ele => {
            if (ele?.types) {
              ele.types.forEach(ele1 => {obj[ele1] = ele.long_name})
            }
          })
          let addressObj = { ...obj };
          let patchValues = {city: addressObj['administrative_area_level_2'],state: addressObj['administrative_area_level_1'],zipcode: addressObj['postal_code']}
          const locationName = results[0].formatted_address;
          this.locationData = {
            coords: this.coords,
            name: locationName,
            postal: addressObj['postal_code']
          }
          // You can store/use the location name as needed
        } else {
        }
      } else {
      }
    });
  }

  getMyLocation(){
    navigator.geolocation.getCurrentPosition((postition:any) =>{
      this.coords = { lat: postition.coords.latitude, lng: postition.coords.longitude };
      this.center = { lat: postition.coords.latitude, lng: postition.coords.longitude };
      this.getPlaceName(this.coords.lat, this.coords.lng);
      this.isLocationChoosen = true
    },
    (e)=>{},
    { enableHighAccuracy: true })
  }
}

interface coordinates{
  lat:number,
  lng:number
}