<div id="code">
    <div mat-dialog-title class="d-flex justify-content-center">
        <img src="assets/logo.png" loading="lazy" style="width: 60%; margin-top: 15px;"/>
        <ng-container *ngIf="!hide">
        <mat-icon class="menu"[matMenuTriggerFor]="menu" style="cursor: pointer;">more_vert</mat-icon>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="convertAndDownload()">
                <mat-icon>download</mat-icon>
                <span>Download</span>
            </button>
        </mat-menu>
        </ng-container>
    </div>
    <div mat-dialog-content>
        <img class="align-cen" align="center" src="{{qrCode}}" loading="lazy" style="width: 50%;height: fit-content;">
        <div>
            <p class="lstNme_1"><span class="lstNme_2">{{data?.listingName}} ({{data?.listingUniqueID}})</span></p>
        </div>
    </div>
</div>