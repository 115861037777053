<div class="right-fix" mat-dialog-actions>
  <h1 mat-dialog-title tabindex="-1" cdkFocusInitial align="center">Cancel Order</h1>
  <button mat-button mat-dialog-close><span>&times;</span></button>
</div>
<div mat-dialog-content>
  <span>Only the pending products can be canceled.</span>&nbsp;&nbsp;
  Are you Sure you want to cancel the order?
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close class="btn" (click)="close()">No</button>
  <button mat-button mat-dialog-close class="btn" cdkFocusInitial (click)="cancelOrderRequest()" [disabled]="loading"><span *ngIf="loading"><i class="fa fa-spinner"></i></span>Yes</button>
</div>